/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 4
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/monmarche/Production/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'devstaging';

export const ApiKey: Record<Environment, string> = {
  production: '',
  devstaging: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '4',
    branch: 'main',
    source: 'web',
    versionId: '52c0a1a5-b879-4b94-9d99-41b898ad29f3'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  "[AppsFlyer] adgroup name"?: any;
  "[AppsFlyer] apple keyword id"?: any;
  "[AppsFlyer] apple_keyword_id"?: any;
  "[AppsFlyer] campaign"?: any;
  "[AppsFlyer] channel"?: any;
  "[AppsFlyer] creative name"?: any;
  "[AppsFlyer] installed at"?: any;
  "[AppsFlyer] media source"?: any;
  "[AppsFlyer] network name"?: any;
  "[AppsFlyer] search term"?: any;
  "[AppsFlyer] sub publisher"?: any;
  /**
   * URL de la page sur laquelle se trouve le user (une page = une URL dédiée)
   */
  "actual page viewed path"?: string;
  /**
   * URL de la page sur laquelle se trouve le user (une page = une URL dédiée)
   */
  "Actual page viewed path"?: string;
  /**
   * Type de page sur laquelle se trouve le user (une page = Une URL dédiée)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Home, Brand, Landing, Product, Marketing, Recipe, Category, User Portal, Search, Root, Checkout, My Products, No Network |
   */
  "actual page viewed type"?:
    | "Home"
    | "Brand"
    | "Landing"
    | "Product"
    | "Marketing"
    | "Recipe"
    | "Category"
    | "User Portal"
    | "Search"
    | "Root"
    | "Checkout"
    | "My Products"
    | "No Network";
  /**
   * Type de page sur laquelle se trouve le user (une page = Une URL dédiée)
   */
  "Actual page viewed type"?: string;
  /**
   * page name of the last page seen
   */
  "current page name"?: any;
  /**
   * page path of the last page seen
   */
  "current page path"?: any;
  /**
   * page type of the last page seen :
   *
   * homepage, category page, subcategory page, landing page,marketing page,user portal page,checkout page
   */
  "current page type viewed"?: any;
  /**
   * Il s'agit du nom de la zone de livraison / PDR renseigné côté BO
   */
  "delivery zone/pick-up point"?: string;
  display_density?: any;
  experiments?: any;
  fbclid?: any;
  gclid?: any;
  initial_fbclid?: any;
  initial_gclid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  "iOS ATT authorization"?: any;
  is_accessibility_enabled?: any;
  is_internal?: any;
  "lucky cart eligible"?: any;
  "notification enabled"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | denied, authorized |
   */
  "optin mail"?: "denied" | "authorized";
  /**
   * **permet de différencier si le client est sur une commande initiale ou sur un ajout de commande.**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new order, additional order |
   */
  "order type"?: "new order" | "additional order";
  "pickup mode"?: any;
  "pickup type"?: any;
  "promo code"?: any;
  "referral code"?: any;
  referrer?: any;
  referring_domain?: any;
  "shipping slot"?: any;
  "shipping slot days"?: any;
  "shipping slot end time"?: any;
  "shipping slot start time"?: any;
  "shipping type"?: any;
  shop?: any;
  "source type"?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | denied, authorized |
   */
  "subscribe sms"?: "denied" | "authorized";
  "subscriptions authorization"?: any;
  /**
   * Valeur renseignée par le nouvel utilisateur dans le formulaire d'inscription : "Comment nous avez-vous connu ?"
   */
  "user acquisition channel"?: string;
  "user business vertical"?: any;
  "user referral code"?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
}

export interface AmplitudeAttributionCapturedProperties {
  channel?: any;
  fbclid?: any;
  gclid?: any;
  referrer?: any;
  referring_domain?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
}

export interface AccountCreatedProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Mode de vérification du compte - une seule option aujourd'hui : email
   */
  "registration account type"?: any;
}

export interface AccountModifiedProperties {
  /**
   * Mode de vérification du compte - Une seule option aujourd'hui : email
   */
  "verification method"?: string;
}

export interface AddIngredientsPageViewedProperties {
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Catégorie depuis laquelle l'utilisateur a accédé à la recette.
   *  Est vide si l'utilisateur arrive directement sur la page Recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category"?: string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source"?: string;
  /**
   * ID technique de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * Nom de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name": string;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
}

export interface AddIngredientsToCartProperties {
  /**
   * Emplacement du clic dans une page
   *
   * Exemple : 2 CTA d'ajout des ingrédients dans la page recette, différencier la source du clic
   */
  "click location": string;
  /**
   * Nombre d'articles ajoutés au panier lors de l'ajout d'une recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of articles": number;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list |
   */
  "product event source"?:
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list";
  /**
   * Catégorie de la recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category": string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source": string;
  /**
   * ID technique de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * Nom de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "recipe price": number;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
}

export interface AdditionalOrderCompletedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
}

export interface AdditionalOrderSubmittedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface BrandPageViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
}

export interface CardMarketingClickProperties {
  /**
   * Position de la card dans la list, tient compte des card recettes, produits, et marketing.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "card position in the list"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  "content name": string;
  link?: string;
  /**
   * Type de lien derrière une card Marketing, peut avoir comme valeur :
   *
   * * marketing modal
   *
   * * URL
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | marketing modal, URL |
   */
  "link type": "marketing modal" | "URL";
  /**
   * Nombre de cards dans la liste (tient compte des produits, recettes, et card marketing)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of cards in the list"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Nombre de produits dans la liste, peut être différent du nombre de card (avec les card recettes, ou marketing par exemple).
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products in the list"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface CartChangesModalViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products affected by a change": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products to be completed": number;
  /**
   * Nombre de produits indisponibles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products to be replaced": number;
}

export interface CartViewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Nombre de produits affectés par un changement dans le panier (Prix ou valeur d'un attribut variable)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "number of products affected by a change"?: number;
  /**
   * Nombre de produits incomplets dans dans le panier (la quantité disponible n'est plus égale à la quantité demandée par l'utilisateur)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "number of products to be completed"?: number;
  /**
   * Nombre de produits devenus complétement indispoble dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "number of products to be replaced"?: number;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface CategoryPageViewedProperties {
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
}

export interface CheckoutAdditionalOrderClickGoToPaymentProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface CheckoutAdditionalOrderStartedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface CheckoutAdditionalOrderViewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface CheckoutCrossSellViewedProperties {
  /**
   * ID de l'algo de cross
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm: string;
  /**
   * **Unic execution ID (allow the Data to match informations)**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * **List of product displayed in the page**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
}

export interface CheckoutStartedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface CheckoutStep1ViewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Etape du checkout sur laquelle se trouve le user
   */
  "checkout step"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * **Is the optin pre-checked checkbox displayed during the checkout**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes, no |
   */
  "opt-in revival"?: "yes" | "no";
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * A supprimer - toujours "express"
   */
  "shipping method"?: string;
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface CheckoutStep2ViewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Etape du checkout sur laquelle se trouve le user
   */
  "checkout step"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * **Is the optin pre-checked checkbox displayed during the checkout**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes, no |
   */
  "opt-in revival"?: "yes" | "no";
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * A supprimer - toujours "express"
   */
  "shipping method"?: string;
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface ChooseAuthorizationLevelProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | authorized, denied |
   */
  "authorization level": "authorized" | "denied";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | iOS ATT, optin mail, subscribe sms, marketing_notifications, transactional_notifications |
   */
  "authorization name":
    | "iOS ATT"
    | "optin mail"
    | "subscribe sms"
    | "marketing_notifications"
    | "transactional_notifications";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | signup, checkout, preferences, app opened |
   */
  "authorization source": "signup" | "checkout" | "preferences" | "app opened";
}

export interface ClearTheCartClickCtaValidatedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface ClickAddAllProductsToCartProperties {
  /**
   * When the user
   */
  "add or remove status"?: any;
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | orders list, favorites list |
   */
  "product event source": "orders list" | "favorites list";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "total number of products": number;
}

export interface ClickAddPortionProperties {
  /**
   * Emplacement du clic dans une page
   *
   * Exemple : 2 CTA d'ajout des ingrédients dans la page recette, différencier la source du clic
   */
  "click location": string;
  /**
   * Quantité de portions paramétrés pour une liste d'ingrédients d'une recette
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "portion quantity": number;
  /**
   * Catégorie depuis laquelle l'utilisateur a accédé à la recette.
   *  Est vide si l'utilisateur arrive directement sur la page Recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category"?: string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source"?: string;
  /**
   * ID technique de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * Nom de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name": string;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
  /**
   * Type de recette : Plat, Entrée, Dessert...
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe type"?: string;
}

export interface ClickAddToCartProperties {
  /**
   * La valeur dépend de si les conditions d'ajout son réunis pour que l'ajout soit effectif.
   *
   * Utiliser le filtre sur "product added" pour être sûr d'avoir un ajout au panier effectif.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | product added, need slot, need shipping, product removed, need login |
   */
  "add or remove status"?: "product added" | "need slot" | "need shipping" | "product removed" | "need login";
  /**
   * Identifiant technique de l'algo DATA
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * Identifiant technique de l'éxécution de l'algo (unique)
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * À supprimer ?
   */
  "attribution token"?: any;
  /**
   * Position de la card dans la list, tient compte des card recettes, produits, et marketing.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "card position in the list"?: number;
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Nom de la catégorie
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie dans la liste des catégories.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "category position"?: number;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Granularité de ventes multiples du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "mdv3 granularity amount"?: number;
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nombre de cards dans la liste (tient compte des produits, recettes, et card marketing)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of cards in the list"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Nombre de produits dans la liste, peut être différent du nombre de card (avec les card recettes, ou marketing par exemple).
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products in the list"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nom de la catégorie Produit
   */
  "product category"?: string;
  /**
   * DLC affiché du produit
   */
  "product consumption date"?: string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Liste des ID produits ci la liste est chargée depuis un algo Data
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Nom de la catégorie PIM du produit
   */
  "product pim"?: string;
  /**
   * Position du produit dans la liste
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "product position in the list"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  /**
   * Quantité du produit ajouté
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "product quantity": number;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  "product subcategory"?: string;
  /**
   * Type de promotion :
   *
   * * IMMEDIATE_DISCOUNT : Promo classique, ex : -20%
   *
   * * BATCH_DISCOUNT : Promo BOGOF, ex : 2+1 offert
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | IMMEDIATE_DISCOUNT, BATCH_DISCOUNT |
   */
  "promo mechanism"?: "IMMEDIATE_DISCOUNT" | "BATCH_DISCOUNT";
  /**
   * Pourcentage de la promo.
   *
   * Si BOGOF : Pourcentage en cas d'ajout de la promo, par exemple : 2+1 offert correspond à 33% de réduction.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "promo percent"?: number;
  /**
   * Uniquement pour les BOGOF : à partir de combien de produit la promo est déclenché.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "promo triggering quantity"?: number;
  /**
   * Position de la recette dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "recipe position in the list"?: number;
  /**
   * ID unique de la recherche
   */
  "search ID"?: string;
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * SKU du produit
   */
  SKU?: string;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface ClickAddToFavoritesListProperties {
  /**
   * La valeur dépend de si les conditions d'ajout son réunis pour que l'ajout soit effectif.
   *
   * Utiliser le filtre sur "product added" pour être sûr d'avoir un ajout au panier effectif.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | product added, need slot, need shipping, product removed, need login |
   */
  "add or remove status"?: "product added" | "need slot" | "need shipping" | "product removed" | "need login";
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Nom de la catégorie PIM du produit
   */
  "product pim": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  "product subcategory"?: string;
  /**
   * SKU du produit
   */
  SKU?: string;
}

export interface ClickBackProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
}

export interface ClickCardProductProperties {
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * **Unic execution ID (allow the Data to match informations)**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * Position de la card dans la list, tient compte des card recettes, produits, et marketing.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "card position in the list"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * Nombre de cards dans la liste (tient compte des produits, recettes, et card marketing)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of cards in the list"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Nombre de produits dans la liste, peut être différent du nombre de card (avec les card recettes, ou marketing par exemple).
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products in the list"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Position du produit dans la liste
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "product position in the list"?: number;
  /**
   * Position de la recette dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "recipe position in the list"?: number;
  /**
   * ID unique de la recherche
   */
  "search ID"?: string;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface ClickCardRecipeProperties {
  /**
   * Position de la card dans la list, tient compte des card recettes, produits, et marketing.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "card position in the list"?: number;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * Nombre de cards dans la liste (tient compte des produits, recettes, et card marketing)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of cards in the list"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "number of recipes in the list"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * ID technique de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * Nom de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name": string;
  /**
   * Position de la recette dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "recipe position in the list"?: number;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface ClickCgvCheckboxProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | authorized, denied |
   */
  "authorization level": "authorized" | "denied";
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
}

export interface ClickConfirmReplacementProperties {
  "initial product name"?: string;
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  "product subcategory"?: string;
}

export interface ClickCouponTabProperties {
  /**
   * Si l'utilisateur clique sur le bouton "Produits" ou "Recettes" depuis les résultats de recherche.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | en cours, expirés |
   */
  content: "en cours" | "expirés";
}

export interface ClickCtaProperties {
  "cta button text"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name"?: string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
}

export interface ClickCtaCompleteIncompleteProductsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products to be completed": number;
}

export interface ClickCtaEditSlotProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive, - |
   */
  "pickup mode": "collect" | "drive" | "-";
  /**
   * Indique si le mode de retrait est du sur place (QG du goût), déporté ou sur place classique (darkstore)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shop, on site, remote, - |
   */
  "pickup type"?: "shop" | "on site" | "remote" | "-";
  "shipping slot": string;
  /**
   * Le Delta entre la date du jour et la date du créneau de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "shipping slot days": number;
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
}

export interface ClickCtaGeolocationProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
}

export interface ClickCtaMakeNewOrderProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slot, address |
   */
  "modification type"?: "slot" | "address";
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface ClickCtaOrderDetailsProperties {
  /**
   * Heure d'arrivée estimée de la livraison.
   *
   * Peut renvoyer "no estimated arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "estimated arrival"?: string;
  /**
   * Heure d'arrivée max. estimée de la livraison.
   *
   * Peut renvoyer "no latest arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "latest arrival"?: string;
  /**
   * Nombre de clients à livrer avant la livraison de l'utilisateur.
   *
   * Est égal à "no rank given" si la tournée n'est pas démarrée.
   */
  "number of customer before"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Créneau de livraison de l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order slot": string;
  /**
   * Statut de la commande concernée par l'event
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PANIER, PRÉ-PAYÉE, EN PRÉPARATION, PRÉPARÉE, EN LIVRAISON, LIVRÉE, DISPONIBLE, RECUPÉRÉE, PAYÉE |
   */
  "order status"?:
    | "PANIER"
    | "PRÉ-PAYÉE"
    | "EN PRÉPARATION"
    | "PRÉPARÉE"
    | "EN LIVRAISON"
    | "LIVRÉE"
    | "DISPONIBLE"
    | "RECUPÉRÉE"
    | "PAYÉE";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | no task status, Assigned, Cancelled, Created, Delivered, Failed, HandingOverContainers, InDelivery |
   */
  "task status"?:
    | "no task status"
    | "Assigned"
    | "Cancelled"
    | "Created"
    | "Delivered"
    | "Failed"
    | "HandingOverContainers"
    | "InDelivery";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | InDelivery, no tour status, Assigned, LoadingVehicle, RetrievingContainers, Created, Done, Returning |
   */
  "tour status"?:
    | "InDelivery"
    | "no tour status"
    | "Assigned"
    | "LoadingVehicle"
    | "RetrievingContainers"
    | "Created"
    | "Done"
    | "Returning";
}

export interface ClickCtaReplaceOutOfStockProductsProperties {
  /**
   * Nombre de produits indisponibles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products to be replaced": number;
}

export interface ClickCtaSendMyEMailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "event source"?: string;
}

export interface ClickCtaShowMoreProductsProperties {
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
}

export interface ClickCtaSimilarProductsProperties {
  "initial product name"?: string;
  /**
   * Nom de la catégorie Produit
   */
  "product category"?: string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  "product subcategory"?: string;
  /**
   * Catégorie depuis laquelle l'utilisateur a accédé à la recette.
   *  Est vide si l'utilisateur arrive directement sur la page Recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category"?: string;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
}

export interface ClickIngredientItemProperties {
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
}

export interface ClickIngredientPickerProperties {
  /**
   * La valeur dépend de si les conditions d'ajout son réunis pour que l'ajout soit effectif.
   *
   * Utiliser le filtre sur "product added" pour être sûr d'avoir un ajout au panier effectif.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | product added, need slot, need shipping, product removed, need login |
   */
  "add or remove status"?: "product added" | "need slot" | "need shipping" | "product removed" | "need login";
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Quantité du produit ajouté
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "product quantity": number;
  "product subcategory"?: string;
}

export interface ClickMegaMenuFamilyPictureProperties {
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nom de la famille de la catégorie
   */
  "family name"?: string;
  /**
   * position de la famille parmi toutes les familles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  "family position"?: number;
  /**
   * Name of the picture
   */
  picture: string;
}

export interface ClickMegaMenuReinsuranceFooterProperties {
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nom de la famille de la catégorie
   */
  "family name"?: string;
  /**
   * position de la famille parmi toutes les familles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  "family position"?: number;
  /**
   * Title of the reinsurance banner in the mega menu footer
   */
  "reinsurance title": string;
}

export interface ClickOpenReplacementOptionsProperties {
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Catégorie depuis laquelle l'utilisateur a accédé à la recette.
   *  Est vide si l'utilisateur arrive directement sur la page Recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category"?: string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source"?: string;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
}

export interface ClickOrderTrackingPanelProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Statut de la commande concernée par l'event
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PANIER, PRÉ-PAYÉE, EN PRÉPARATION, PRÉPARÉE, EN LIVRAISON, LIVRÉE, DISPONIBLE, RECUPÉRÉE, PAYÉE |
   */
  "order status"?:
    | "PANIER"
    | "PRÉ-PAYÉE"
    | "EN PRÉPARATION"
    | "PRÉPARÉE"
    | "EN LIVRAISON"
    | "LIVRÉE"
    | "DISPONIBLE"
    | "RECUPÉRÉE"
    | "PAYÉE";
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive |
   */
  "pickup mode"?: "collect" | "drive";
  "shipping slot": string;
  /**
   * Le Delta entre la date du jour et la date du créneau de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "shipping slot days": number;
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
}

export interface ClickPortionPickerIngredientsListProperties {
  /**
   * Quantité de portions paramétrés pour une liste d'ingrédients d'une recette
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "portion quantity": number;
}

export interface ClickPrivacyPolicyCheckboxProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | authorized, denied |
   */
  "authorization level": "authorized" | "denied";
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
}

export interface ClickProductDetailsProperties {
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
}

export interface ClickProductsInformationChangesTrackingPanelProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products affected by a change": number;
}

export interface ClickRemoveFromFavoritesListProperties {
  /**
   * La valeur dépend de si les conditions d'ajout son réunis pour que l'ajout soit effectif.
   *
   * Utiliser le filtre sur "product added" pour être sûr d'avoir un ajout au panier effectif.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | product added, need slot, need shipping, product removed, need login |
   */
  "add or remove status"?: "product added" | "need slot" | "need shipping" | "product removed" | "need login";
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Nom de la catégorie PIM du produit
   */
  "product pim": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  "product subcategory"?: string;
}

export interface ClickRemoveProductProperties {
  /**
   * La valeur dépend de si les conditions d'ajout son réunis pour que l'ajout soit effectif.
   *
   * Utiliser le filtre sur "product added" pour être sûr d'avoir un ajout au panier effectif.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | product added, need slot, need shipping, product removed, need login |
   */
  "add or remove status"?: "product added" | "need slot" | "need shipping" | "product removed" | "need login";
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * **Unic execution ID (allow the Data to match informations)**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * Position de la card dans la list, tient compte des card recettes, produits, et marketing.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "card position in the list"?: number;
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * Granularité de ventes multiples du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "mdv3 granularity amount"?: number;
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nombre de cards dans la liste (tient compte des produits, recettes, et card marketing)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of cards in the list"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Nombre de produits dans la liste, peut être différent du nombre de card (avec les card recettes, ou marketing par exemple).
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of products in the list"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Nom de la catégorie PIM du produit
   */
  "product pim"?: string;
  /**
   * Position du produit dans la liste
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "product position in the list"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  /**
   * Quantité du produit ajouté
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "product quantity": number;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  "product subcategory"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "promo mechanism"?: string;
  /**
   * Pourcentage de la promo.
   *
   * Si BOGOF : Pourcentage en cas d'ajout de la promo, par exemple : 2+1 offert correspond à 33% de réduction.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "promo percent"?: number;
  /**
   * Uniquement pour les BOGOF : à partir de combien de produit la promo est déclenché.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "promo triggering quantity"?: number;
  /**
   * Position de la recette dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "recipe position in the list"?: number;
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface ClickSearchResultsTabProperties {
  /**
   * Si l'utilisateur clique sur le bouton "Produits" ou "Recettes" depuis les résultats de recherche.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products, recipes |
   */
  content: "products" | "recipes";
}

export interface ClickTrashBinDeleteTheProductProperties {
  /**
   * Disponibilité du produit.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | en stock, indisponible, incomplet |
   */
  "cart stock status": "en stock" | "indisponible" | "incomplet";
  /**
   * Source de l'add to cart
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products list, main product, search results, checkout main product, lastminute cross sell, checkout cross sell, cart, no result search, orders list, replacement flow, frequent purchases, favorites list, completion flow, similar products list, recipe ingredients list, my products, search cross sell, lastminute cross sell 2, lastminute cross sell 3, lastminute cross sell 4, ingredients substitutes modal, essentials recipe ingredients list, similar cross sell, complementary cross sell, acknowledgement |
   */
  "product event source":
    | "products list"
    | "main product"
    | "search results"
    | "checkout main product"
    | "lastminute cross sell"
    | "checkout cross sell"
    | "cart"
    | "no result search"
    | "orders list"
    | "replacement flow"
    | "frequent purchases"
    | "favorites list"
    | "completion flow"
    | "similar products list"
    | "recipe ingredients list"
    | "my products"
    | "search cross sell"
    | "lastminute cross sell 2"
    | "lastminute cross sell 3"
    | "lastminute cross sell 4"
    | "ingredients substitutes modal"
    | "essentials recipe ingredients list"
    | "similar cross sell"
    | "complementary cross sell"
    | "acknowledgement";
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
}

export interface CodeAcceptedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Code saisi par l'utilisateur
   */
  code: string;
  /**
   * Manual > Ajouté par l'utilisateur via le champ de saisi
   *
   * Auto  > Ajouté automatiquement (cas : code des LP)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  mode: "manual" | "auto";
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Doublon avec la property code ?
   */
  "promo code"?: any;
  /**
   * Code parrain utilisé par le user (et non son code personnel)
   */
  "referral code"?: string;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface CodeAppliedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Code saisi par l'utilisateur
   */
  code: string;
  /**
   * Manual > Ajouté par l'utilisateur via le champ de saisi
   *
   * Auto  > Ajouté automatiquement (cas : code des LP)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  mode: "manual" | "auto";
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface CodeRejectedProperties {
  /**
   * Code saisi par l'utilisateur
   */
  code?: string;
  /**
   * Message de refus du code affiché à l'utilisateur
   */
  "code rejection message": string;
  /**
   * Manual > Ajouté par l'utilisateur via le champ de saisi
   *
   * Auto  > Ajouté automatiquement (cas : code des LP)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  mode: "manual" | "auto";
}

export interface CouponActivatedProperties {
  /**
   * Le nom du coupon (= code promo associé au coupon)
   */
  "coupon name"?: string;
}

export interface CouponDeactivatedProperties {
  /**
   * Le nom du coupon (= code promo associé au coupon)
   */
  "coupon name"?: string;
}

export interface CouponDisabledProperties {
  /**
   * Message affiché sur la modale d'erreur
   */
  "dialog message"?: string;
  /**
   * Titre affiché sur la modale d'erruer
   */
  "dialog title": string;
  "dialog type"?: string;
  "page path"?: any;
}

export interface CreateAccountStartedProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
}

export interface CreateAccountSubmittedProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Mode de vérification du compte - une seule option aujourd'hui : email
   */
  "registration account type"?: any;
}

export interface DeliveryTrackingModalClosedProperties {
  /**
   * Heure d'arrivée estimée de la livraison.
   *
   * Peut renvoyer "no estimated arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "estimated arrival"?: string;
  /**
   * Heure d'arrivée max. estimée de la livraison.
   *
   * Peut renvoyer "no latest arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "latest arrival"?: string;
  /**
   * Nombre de clients à livrer avant la livraison de l'utilisateur.
   *
   * Est égal à "no rank given" si la tournée n'est pas démarrée.
   */
  "number of customer before"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Créneau de livraison de l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order slot": string;
  /**
   * Statut de la commande concernée par l'event
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PANIER, PRÉ-PAYÉE, EN PRÉPARATION, PRÉPARÉE, EN LIVRAISON, LIVRÉE, DISPONIBLE, RECUPÉRÉE, PAYÉE |
   */
  "order status"?:
    | "PANIER"
    | "PRÉ-PAYÉE"
    | "EN PRÉPARATION"
    | "PRÉPARÉE"
    | "EN LIVRAISON"
    | "LIVRÉE"
    | "DISPONIBLE"
    | "RECUPÉRÉE"
    | "PAYÉE";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | no task status, Assigned, Cancelled, Created, Delivered, Failed, HandingOverContainers, InDelivery |
   */
  "task status"?:
    | "no task status"
    | "Assigned"
    | "Cancelled"
    | "Created"
    | "Delivered"
    | "Failed"
    | "HandingOverContainers"
    | "InDelivery";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | InDelivery, no tour status, Assigned, LoadingVehicle, RetrievingContainers, Created, Done, Returning |
   */
  "tour status"?:
    | "InDelivery"
    | "no tour status"
    | "Assigned"
    | "LoadingVehicle"
    | "RetrievingContainers"
    | "Created"
    | "Done"
    | "Returning";
}

export interface DidomiActionProperties {
  action?: string;
  "category Id"?: string;
  "purpose Id"?: string;
  type: string;
  "vendor Id"?: any;
}

export interface EditIngredientQuantityProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "ingredient quantity": number;
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source"?: string;
  /**
   * ID technique de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * Nom de la recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name": string;
}

export interface EditOrderClickCtaValidatedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
  /**
   * Montant des frais de livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total shipping": number;
}

export interface ErrorModalViewProperties {
  /**
   * Message affiché sur la modale d'erreur
   */
  "dialog message"?: string;
  /**
   * Titre affiché sur la modale d'erruer
   */
  "dialog title": string;
  "dialog type"?: string;
  "page path"?: any;
}

export interface ErrorPaymentProperties {
  "apiecom code"?: any;
  "apiecom message"?: any;
  "apiecom statuscode"?: any;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  "paypal error"?: any;
  "stripe 3ds supported"?: any;
  "stripe decline code"?: any;
  "stripe error code"?: any;
  "stripe error type"?: any;
  "stripe localized message"?: any;
  "stripe message"?: any;
  "stripe paymentintent status"?: any;
}

export interface FieldErrorViewedProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "current screen"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "field error content"?: string;
  "field title": string;
}

export interface FrequentPurchasesPageViewedProperties {
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
}

export interface HeaderMarketingClickProperties {
  "content name": string;
  link?: string;
  /**
   * Type de lien derrière une card Marketing, peut avoir comme valeur :
   *
   * * marketing modal
   *
   * * URL
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | marketing modal, URL |
   */
  "link type": "marketing modal" | "URL";
}

export interface HomepageViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
}

export interface ImpossibleAddProductsModalViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  "shipping slot": string;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface IngredientsSubstitutesModalViewedProperties {
  "initial product name"?: string;
  /**
   * **Number of similar products viewed on the first page of similar products panel**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "number of similar products viewed"?: number;
}

export interface LandingPageViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  "lucky cart eligible"?: any;
  "user business vertical"?: any;
}

export interface LastOrderViewedProperties {
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
}

export interface LastminuteCrossSellPageViewedProperties {
  /**
   * ID of the algorithm
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * UNIC execution ID (allow the data to match informations)
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * indique si on affiche 1 ou 2 écrans de cross sell last minute (valeurs possibles : 1, 2)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "number of steps": number;
  /**
   * List of product displayed in the page
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
  /**
   * * Indique sur quel écran de cross sell last minute on se trouve (valeurs possibles : 1, 2)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  step: number;
}

export interface LoggedInProperties {
  /**
   * Type de log in - aujourd'hui une seul option : "Email"
   */
  "log in account type"?: string;
}

export interface LoggedInStartedProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
}

export interface LoggedInSubmittedProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shipping slot validated, order slot updated |
   */
  source?: "shipping slot validated" | "order slot updated";
}

export interface MakeNewOrderModalViewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slot, address |
   */
  "modification type"?: "slot" | "address";
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Valeur du panier (somme des montants des produits à payer, avant coupons, frais de prépa et livraison et préauth de 15%)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
}

export interface MarketingModalViewedProperties {
  "content name": string;
}

export interface MarketingPageViewedProperties {
  "content name": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
}

export interface MyProductsCategoryPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
}

export interface MyProductsInformationsModalViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
}

export interface NextLastMinuteCrossSellProperties {
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * **Unic execution ID (allow the Data to match informations)**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "algorithm execution id"?: string;
  /**
   * indique si on affiche 1 ou 2 écrans de cross sell last minute (valeurs possibles : 1, 2)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "number of steps": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "product ids list"?: string;
  /**
   * * Indique sur quel écran de cross sell last minute on se trouve (valeurs possibles : 1, 2)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  step: number;
}

export interface OptInToggleClickedProperties {
  /**
   * Nouvelle valeur du toggle
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | on, off |
   */
  "opt-in status": "on" | "off";
}

export interface OrderCancelledProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Montant final de la commande
   */
  "order total"?: string;
}

export interface OrderCompletedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Code saisi par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  code?: string[];
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Indique si l'utilisateur a choisi de réduire les emaballages (on) ou non (off)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | on, off |
   */
  "packing reduction status": "on" | "off";
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  /**
   * **Slot's shipping cost delta charged (day setup)**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta day charged": number;
  /**
   * **Slot's shipping cost delta (slot setup)**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta slot charged": number;
  /**
   * A supprimer - toujours "express"
   */
  "shipping method"?: string;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
  /**
   * Montant des frais de livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total shipping": number;
  /**
   * Indique si l'utilisateur accepte le remplacement auto de produit (on) ou non (off)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | off, on |
   */
  "unavailable products replacement status": "off" | "on";
}

export interface OrderDeliveryInformationReceivedProperties {
  /**
   * Heure d'arrivée estimée de la livraison.
   *
   * Peut renvoyer "no estimated arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "estimated arrival"?: string;
  /**
   * Heure d'arrivée max. estimée de la livraison.
   *
   * Peut renvoyer "no latest arrival" si l'heure n'est pas encore connue.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "latest arrival"?: string;
  /**
   * Nombre de clients à livrer avant la livraison de l'utilisateur.
   *
   * Est égal à "no rank given" si la tournée n'est pas démarrée.
   */
  "number of customer before"?: string;
  /**
   * ID technique de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order id": string;
  /**
   * Créneau de livraison de l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "order slot": string;
  /**
   * Statut de la commande concernée par l'event
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PANIER, PRÉ-PAYÉE, EN PRÉPARATION, PRÉPARÉE, EN LIVRAISON, LIVRÉE, DISPONIBLE, RECUPÉRÉE, PAYÉE |
   */
  "order status"?:
    | "PANIER"
    | "PRÉ-PAYÉE"
    | "EN PRÉPARATION"
    | "PRÉPARÉE"
    | "EN LIVRAISON"
    | "LIVRÉE"
    | "DISPONIBLE"
    | "RECUPÉRÉE"
    | "PAYÉE";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | no task status, Assigned, Cancelled, Created, Delivered, Failed, HandingOverContainers, InDelivery |
   */
  "task status"?:
    | "no task status"
    | "Assigned"
    | "Cancelled"
    | "Created"
    | "Delivered"
    | "Failed"
    | "HandingOverContainers"
    | "InDelivery";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | InDelivery, no tour status, Assigned, LoadingVehicle, RetrievingContainers, Created, Done, Returning |
   */
  "tour status"?:
    | "InDelivery"
    | "no tour status"
    | "Assigned"
    | "LoadingVehicle"
    | "RetrievingContainers"
    | "Created"
    | "Done"
    | "Returning";
}

export interface OrderReviewedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
}

export interface OrderSubmittedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Code saisi par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  code?: string[];
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  /**
   * A supprimer - toujours "express"
   */
  "shipping method"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total cart": number;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
  /**
   * Montant des frais de livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total shipping": number;
}

export interface PackingReductionToggleClickProperties {
  /**
   * Nouvelle valeur du switch
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | on, off |
   */
  "packing reduction status": "on" | "off";
}

export interface PaymentInfoCompletedProperties {
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Montant total de la préautorisation (ce qui va être capturé à l'utilisateur, incluant les 15% de préauth, les frais de livraison et prépa, les coupons)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "order prepaid": number;
  /**
   * Methode de paiement utilisée : credit card / apple pay / google pay / Paypal
   */
  "payment method": string;
  /**
   * Nombre d'article dans le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "total cart size": number;
  /**
   * Montant des coupons actifs sur le panier
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total discounts"?: number;
}

export interface PhotoModalViewedProperties {
  /**
   * Nom de la catégorie Produit
   */
  "product category"?: string;
  /**
   * Nom du produit
   */
  "product name"?: string;
  "product subcategory"?: string;
}

export interface ProductPageViewedProperties {
  /**
   * À supprimer ?
   */
  "attribution token"?: any;
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nom de la catégorie Produit
   */
  "product category"?: string;
  /**
   * Source de l'add to cart
   */
  "product event source": string;
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Nom de la catégorie PIM du produit
   */
  "product pim"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  "product price": number;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | in stock, out of stock |
   */
  "product stock status": "in stock" | "out of stock";
  "product subcategory"?: string;
  /**
   * Type de promotion :
   *
   * * IMMEDIATE_DISCOUNT : Promo classique, ex : -20%
   *
   * * BATCH_DISCOUNT : Promo BOGOF, ex : 2+1 offert
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | IMMEDIATE_DISCOUNT, BATCH_DISCOUNT |
   */
  "promo mechanism"?: "IMMEDIATE_DISCOUNT" | "BATCH_DISCOUNT";
  /**
   * Pourcentage de la promo.
   *
   * Si BOGOF : Pourcentage en cas d'ajout de la promo, par exemple : 2+1 offert correspond à 33% de réduction.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "promo percent"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "promo triggering quantity"?: number;
}

export interface ReadLessProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
}

export interface ReadMoreProperties {
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
}

export interface RecipePageViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Catégorie depuis laquelle l'utilisateur a accédé à la recette.
   *  Est vide si l'utilisateur arrive directement sur la page Recette.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe category"?: string;
  /**
   * Écran d'où vient l'utilisateur.
   *
   * Est vide si l'utilisateur arrive sur la page Recettes directement.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe event source"?: string;
  /**
   * ID technique de la Recette
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe name"?: string;
  /**
   * Nom de la sous-catégorie de la Recette.
   *  Optionnel car l'info ne peut pas être emontée lorsque l'on vient d'un deeplink sur MCOM.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe subcategory"?: string;
  /**
   * Type de recette : Plat, Entrée, Dessert...
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "recipe type"?: string;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
}

export interface ReferralCodeCopiedProperties {
  /**
   * Code parrain de l'utilisateur
   */
  "referral code": string;
}

export interface ReferralCodeSharedProperties {
  /**
   * Code parrain de l'utilisateur
   */
  "referral code": string;
}

export interface ReferralModalViewedProperties {
  /**
   * Code parrain de l'utilisateur
   */
  "user referral code": string;
}

export interface RollOutPhotoCarouselProperties {
  /**
   * L'utilisateur peut naviguer sur les photos de 2 manières :
   *  - Au clic sur les flèches
   *  - Au clic sur une vignette
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | thumbnails, slider |
   */
  "opened from": "thumbnails" | "slider";
  /**
   * Uniquement pour le clic sur la vignette, pour savoir si l'utilisateur a navigué depuis la modale plein écran de la photo, ou depuis la fiche produit
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | photo modal, product page |
   */
  "photo event source": "photo modal" | "product page";
  /**
   * Nom de la catégorie Produit
   */
  "product category"?: string;
  /**
   * Nom du produit
   */
  "product name": string;
  "product subcategory"?: string;
}

export interface RootPageViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
}

export interface ScrollHorizontalListProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
}

export interface SearchCompletedProperties {
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | homepage, stalls, recipes, products category |
   */
  "event source"?: "homepage" | "stalls" | "recipes" | "products category";
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Content of the search, depend on the search type :
   *
   * * Free = user input
   *
   * * Popular term = popular term selected by the user
   *
   * * Suggestion term = suggestion term selected by the user
   *
   * * Subcategory = name of the subcategory selected by the user
   */
  keywords: string;
  /**
   * number of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of search results"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Correspond aux types de résultats affichés dans la liste (Recettes ou Produits)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products, recipes |
   */
  "results type"?: "products" | "recipes";
  /**
   * ID unique de la recherche
   */
  "search ID"?: string;
  /**
   * Détermine le type de recherche lancée par l'utilisateur : Free (le user a saisie des caractères et tapé sur entrée), Keyword (le user a choisi un mot clé dans la liste des mots clés suggérés, Sucategory (le user a sélectionné une sous-category), Popular term (le user a choisi un mot dans la liste des termes populaires, sans saisir lui même de caractères)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | subcategory, free, suggestion, Popular term |
   */
  "search type"?: "subcategory" | "free" | "suggestion" | "Popular term";
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * value of the caracters filled by the user
   */
  "user input": string;
}

export interface SearchStartedProperties {
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * Remonte la source de l'événement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | stalls, homepage, products category, recipes |
   */
  "event source"?: "stalls" | "homepage" | "products category" | "recipes";
  /**
   * Inutile sur "search started"
   */
  "navigation text"?: string;
  /**
   * Nombre de recherche populaires proposées à l'utilisateur au moment ou il démarre sa recherche
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "number of popular terms"?: number;
}

export interface SendProposalProperties {
  /**
   * Suggestion de l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Regex |  |
   */
  "proposal text": string;
}

export interface SendSmsCodeProperties {
  "telephone number"?: any;
}

export interface ShippingCtaClickProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  reason?: string;
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
}

export interface ShippingInformationModalViewedProperties {
  /**
   * ID des zones d'exclusion qui impacte les créneaux vues par l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  "excludedZone id"?: string[];
  /**
   * Type de modales affichés depuis la page de sélection des créneaux.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Delivery informations, Free delivery, Excluded zone, Full shipping slot |
   */
  "modal type": "Delivery informations" | "Free delivery" | "Excluded zone" | "Full shipping slot";
}

export interface ShippingSlotDayViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "available slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  date?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  day?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "free delivery ad"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots rate"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping slot days"?: number;
  /**
   * Le type de créneau. Aujourd'hui "normal" (équivalent à vide anciennement) ou "green" pour les créneaux éco
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | normal, green |
   */
  "slot type"?: "normal" | "green";
  /**
   * Le nombre de créneaux éco visibles (pas forcément disponible)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "total green slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total slots"?: number;
}

export interface ShippingSlotStartedProperties {
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive, - |
   */
  "pickup mode": "collect" | "drive" | "-";
  /**
   * Indique si le mode de retrait est du sur place (QG du goût), déporté ou sur place classique (darkstore)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shop, on site, remote, - |
   */
  "pickup type"?: "shop" | "on site" | "remote" | "-";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
}

export interface ShippingSlotToasterViewedProperties {
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shipping slot validated, order slot updated |
   */
  source?: "shipping slot validated" | "order slot updated";
}

export interface ShippingSlotUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "available slots"?: number;
  /**
   * Identifiant unique du panier / de la commande
   */
  "cart id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  date?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  day?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots rate"?: number;
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive, - |
   */
  "pickup mode": "collect" | "drive" | "-";
  /**
   * Indique si le mode de retrait est du sur place (QG du goût), déporté ou sur place classique (darkstore)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shop, on site, remote, - |
   */
  "pickup type"?: "shop" | "on site" | "remote" | "-";
  "shipping slot": string;
  /**
   * Le Delta entre la date du jour et la date du créneau de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "shipping slot days"?: number;
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Le type de créneau. Aujourd'hui "normal" (équivalent à vide anciennement) ou "green" pour les créneaux éco
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | normal, green |
   */
  "slot type"?: "normal" | "green";
  /**
   * Le nombre de créneaux éco visibles (pas forcément disponible)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "total green slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total slots"?: number;
  /**
   * Créneau/adresse attribué automatiquement ou manuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  "validation mode"?: "manual" | "auto";
}

export interface ShippingSlotValidatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "available slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  date?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  day?: string;
  /**
   * Il s'agit du nom de la zone de livraison / PDR renseigné côté BO
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "delivery zone/pick-up point": string;
  "delta shipping slot start time"?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "event source"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "free delivery ad"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "full slots rate"?: number;
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive, - |
   */
  "pickup mode": "collect" | "drive" | "-";
  /**
   * Indique si le mode de retrait est du sur place (QG du goût), déporté ou sur place classique (darkstore)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shop, on site, remote, - |
   */
  "pickup type"?: "shop" | "on site" | "remote" | "-";
  /**
   * Dans le cadre d'un changement automatique de créneau, indique la raison (créneau initial complet, expiré, supprimé...)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new address, full, expired, excluded, deleted, no_delivery_for_address, unknown, manual |
   */
  "reason for change":
    | "new address"
    | "full"
    | "expired"
    | "excluded"
    | "deleted"
    | "no_delivery_for_address"
    | "unknown"
    | "manual";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta day"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta day max"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta day min"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta slot"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta slot max"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost delta slot min"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost viewed"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost viewed max"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "shipping cost viewed min"?: number;
  /**
   * Le Delta entre la date du jour et la date du créneau de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "shipping slot days"?: number;
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Le type de créneau. Aujourd'hui "normal" (équivalent à vide anciennement) ou "green" pour les créneaux éco
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | normal, green |
   */
  "slot type"?: "normal" | "green";
  /**
   * Le nombre de créneaux éco visibles (pas forcément disponible)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "total green slots"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total slots"?: number;
  /**
   * Créneau/adresse attribué automatiquement ou manuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  "validation mode"?: "manual" | "auto";
}

export interface ShippingTypeRefusedProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Etape en cours dans la soumission/sélection de l'adresse
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enter the address, select the shop/pickup, select the shop, select the mode, select the pickup mode, select the manual address, select the postal code, select the street, select the position |
   */
  "shipping type step"?:
    | "enter the address"
    | "select the shop/pickup"
    | "select the shop"
    | "select the mode"
    | "select the pickup mode"
    | "select the manual address"
    | "select the postal code"
    | "select the street"
    | "select the position";
}

export interface ShippingTypeStartedProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Etape en cours dans la soumission/sélection de l'adresse
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enter the address, select the shop/pickup, select the shop, select the mode, select the pickup mode, select the manual address, select the postal code, select the street, select the position |
   */
  "shipping type step"?:
    | "enter the address"
    | "select the shop/pickup"
    | "select the shop"
    | "select the mode"
    | "select the pickup mode"
    | "select the manual address"
    | "select the postal code"
    | "select the street"
    | "select the position";
}

export interface ShippingTypeSubmittedProperties {
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Etape en cours dans la soumission/sélection de l'adresse
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enter the address, select the shop/pickup, select the shop, select the mode, select the pickup mode, select the manual address, select the postal code, select the street, select the position |
   */
  "shipping type step"?:
    | "enter the address"
    | "select the shop/pickup"
    | "select the shop"
    | "select the mode"
    | "select the pickup mode"
    | "select the manual address"
    | "select the postal code"
    | "select the street"
    | "select the position";
}

export interface ShippingTypeValidatedProperties {
  /**
   * Il s'agit du nom de la zone de livraison / PDR renseigné côté BO
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "delivery zone/pick-up point": string;
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Indique si le retrait a été choisi en drive ou piéton
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collect, drive, - |
   */
  "pickup mode": "collect" | "drive" | "-";
  /**
   * Indique si le mode de retrait est du sur place (QG du goût), déporté ou sur place classique (darkstore)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shop, on site, remote, - |
   */
  "pickup type"?: "shop" | "on site" | "remote" | "-";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Indique à quel atelier l'adresse/PDR choisi est lié
   */
  shop?: string;
  /**
   * Créneau/adresse attribué automatiquement ou manuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, auto |
   */
  "validation mode"?: "manual" | "auto";
}

export interface ShippingTypeViewedProperties {
  /**
   * Identifie si l'event est triggered depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, onboarding |
   */
  "event source": "navigation" | "onboarding";
  /**
   * Indique si le user est sur l'onglet retrait ou livraison
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | delivery, pickup |
   */
  "shipping type": "delivery" | "pickup";
  /**
   * Etape en cours dans la soumission/sélection de l'adresse
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enter the address, select the shop/pickup, select the shop, select the mode, select the pickup mode, select the manual address, select the postal code, select the street, select the position |
   */
  "shipping type step"?:
    | "enter the address"
    | "select the shop/pickup"
    | "select the shop"
    | "select the mode"
    | "select the pickup mode"
    | "select the manual address"
    | "select the postal code"
    | "select the street"
    | "select the position";
}

export interface SimilarProductsListViewedProperties {
  /**
   * **Number of similar products viewed on the first page of similar products panel**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "number of similar products viewed"?: number;
  /**
   * **Number of products found by the algo**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total number of similar products found"?: number;
}

export interface SmsCodeAcceptedProperties {
  "telephone number"?: any;
}

export interface SmsCodeRefusedProperties {
  "telephone number"?: any;
}

export interface SubcategoryClickTitleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface SubcategoryPageViewedProperties {
  /**
   * List of the products available (format JSON)
   *
   * `{ id: string, // ID canonique `
   *
   * `sku: string, // Sku du produit `
   *
   * `stock: number, // Quantité dispo `
   *
   * `index: number, // Position dans la liste globale }`
   */
  "available products"?: any[];
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface SubcategoryViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface UnavailableProductsReplacementToggleClickProperties {
  /**
   * Indique si l'utilisateur accepte le remplacement auto de produit (on) ou non (off)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | off, on |
   */
  "unavailable products replacement status": "off" | "on";
}

export interface UserNavigationCardCategoryProperties {
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nom de la famille de la catégorie
   */
  "family name"?: string;
  /**
   * position de la famille parmi toutes les familles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  "family position"?: number;
  /**
   * Text the describes the navigation element that was clicked
   */
  "navigation text": string;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   */
  source?: string;
}

export interface UserNavigationFooterProperties {
  "navigation text"?: string;
}

export interface UserNavigationHeaderProperties {
  "navigation text"?: string;
}

export interface UserNavigationMenuProperties {
  "navigation text"?: string;
}

export interface UserNavigationMenuCategoryProperties {
  "category Id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nom de la famille de la catégorie
   */
  "family name"?: string;
  /**
   * position de la famille parmi toutes les familles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  "family position"?: number;
  "navigation text"?: string;
  /**
   * Nombre de catégories disponibles pour l'utilisateur.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of categories"?: number;
  /**
   * Source d'accès à une catégorie produits
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Homepage, Burger menu, Stalls, Mega menu see all, Mega menu title, Treeview, Tabs |
   */
  source?: "Homepage" | "Burger menu" | "Stalls" | "Mega menu see all" | "Mega menu title" | "Treeview" | "Tabs";
}

export interface UserNavigationMenuFamilyProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * identifiant techique de la famille de la catégorie
   */
  "family id"?: string;
  /**
   * Nom de la famille de la catégorie
   */
  "family name"?: string;
  /**
   * position de la famille parmi toutes les familles
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   */
  "family position"?: number;
}

export interface UserNavigationMenuMyProductsCategoryProperties {
  "category Id"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "category name"?: string;
  /**
   * Position de la catégorie au sein de la famille
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "category position"?: number;
}

export interface UserNavigationMenuPortalProperties {
  "navigation text"?: string;
}

export interface UserNavigationMenuSubcategoryProperties {
  "navigation text"?: string;
  /**
   * Nombre de sous-catégories affichées
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of subcategories"?: number;
  /**
   * Source de choix de créneau : choix initial OU modification de créneau à postériori de la commande
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | mega menu, treeview |
   */
  source?: "mega menu" | "treeview";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "subcategory name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "subcategory position"?: number;
}

export interface UserPortalViewedProperties {
  /**
   * Nom de la page sur laquelle se trouve l'utilisateur
   */
  "current page name": string;
  /**
   * Chemin de la page sur laquelle se trouve l'utilisateur
   */
  "current page path"?: string;
  /**
   * Type de page sur laquelle se trouve l'utilisateur
   */
  "current page type viewed": string;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
}

export interface ViewAllReviewsProperties {
  /**
   * Nombre de notes du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "nb of ratings"?: number;
  /**
   * Nom de la catégorie Produit
   */
  "product category": string;
  /**
   * Identifiant technique du produit
   */
  "product id": string;
  /**
   * Nom du produit
   */
  "product name": string;
  /**
   * Note moyenne du produit
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  "product rating"?: number;
  "product subcategory"?: string;
  /**
   * SKU du produit
   */
  SKU?: string;
}

export interface ViewFavoritesListProperties {
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "total number of products": number;
}

export interface ViewSearchResultsProperties {
  /**
   * **Id of the algorithm**
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  algorithm?: string;
  /**
   * List of the products available (format JSON)
   *
   * `{ id: string, // ID canonique `
   *
   * `sku: string, // Sku du produit `
   *
   * `stock: number, // Quantité dispo `
   *
   * `index: number, // Position dans la liste globale }`
   */
  "available products"?: any[];
  /**
   * Remonte si l'event a eu lieu depuis la navigation classique ou l'onboarding
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | homepage, stalls, products category, recipes |
   */
  "event source"?: "homepage" | "stalls" | "products category" | "recipes";
  /**
   * Nombre de produits disponibles dans la liste.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "in stock products"?: number;
  /**
   * Si valeur de la propriété Is page Viewed = Yes, alors cela veut dire que le client a changé de page (= nouvelle URL)
   *  Sur les events qui correspondent à des interactions (clicks, ouvertures de modale...), cette propriété n'est pas présente
   */
  "is page viewed": boolean;
  /**
   * Content of the search, depend on the search type :
   *
   * * Free = user input
   *
   * * Popular term = popular term selected by the user
   *
   * * Suggestion term = suggestion term selected by the user
   *
   * * Subcategory = name of the subcategory selected by the user
   */
  keywords: string;
  /**
   * number of search results
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "number of search results"?: number;
  /**
   * Nombre de produits indisponibles dans la liste.
   *  N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "out of stock products"?: number;
  /**
   * Taux de produits indisponibles dans la liste.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "out of stock rate"?: number;
  /**
   * Numéro de la page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  "page index": number;
  /**
   * Correspond aux types de résultats affichés dans la liste (Recettes ou Produits)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | products, recipes |
   */
  "results type"?: "products" | "recipes";
  /**
   * ID unique de la recherche
   */
  "search ID"?: string;
  /**
   * Détermine le type de recherche lancée par l'utilisateur : Free (le user a saisie des caractères et tapé sur entrée), Keyword (le user a choisi un mot clé dans la liste des mots clés suggérés, Sucategory (le user a sélectionné une sous-category), Popular term (le user a choisi un mot dans la liste des termes populaires, sans saisir lui même de caractères)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | subcategory, free, suggestion, Popular term |
   */
  "search type"?: "subcategory" | "free" | "suggestion" | "Popular term";
  /**
   * Nombre de produits indispos proposant la fonctionnalité de produits similaires.
   *
   * N'est pas renvoyé pour les recettes.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "similar products activated"?: number;
  /**
   * Taux de produits indisponibles avec des produits similaires proposés.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  "similar products activated rate"?: number;
  /**
   * List of the products unavailable (format JSON)
   *
   * `{ id: string, // ID canonique `
   *
   * `sku: string, // Sku du produit `
   *
   * `stock: number, // Quantité dispo `
   *
   * `index: number, // Position dans la liste globale }`
   */
  "unavailable products"?: any[];
}

export interface ViewSuggestionsResultsProperties {
  /**
   * Nombre total de propositions affichées à l’utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of results": number;
  /**
   * Nombre de sous-catégories proposées à l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of subcategories": number;
  /**
   * Nombre de termes suggérés au user (issus des catégories PIM de différents niveaux)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of suggestions": number;
  /**
   * value of the caracters filled by the user
   */
  "user input": string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeAttributionCaptured implements BaseEvent {
  event_type = '[Amplitude] Attribution Captured' as const;

  constructor(
    public event_properties?: AmplitudeAttributionCapturedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'account created' as const;

  constructor(
    public event_properties?: AccountCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountModified implements BaseEvent {
  event_type = 'account modified' as const;

  constructor(
    public event_properties?: AccountModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCreditCard implements BaseEvent {
  event_type = 'add credit card' as const;
}

export class AddIngredientsPageViewed implements BaseEvent {
  event_type = 'add ingredients page viewed' as const;

  constructor(
    public event_properties: AddIngredientsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddIngredientsToCart implements BaseEvent {
  event_type = 'add ingredients to cart' as const;

  constructor(
    public event_properties: AddIngredientsToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdditionalOrderCompleted implements BaseEvent {
  event_type = 'additional order completed' as const;

  constructor(
    public event_properties: AdditionalOrderCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdditionalOrderSubmitted implements BaseEvent {
  event_type = 'additional order submitted' as const;

  constructor(
    public event_properties: AdditionalOrderSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandPageViewed implements BaseEvent {
  event_type = 'brand page viewed' as const;

  constructor(
    public event_properties: BrandPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardMarketingClick implements BaseEvent {
  event_type = 'card marketing click' as const;

  constructor(
    public event_properties: CardMarketingClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CartChangesModalViewed implements BaseEvent {
  event_type = 'cart changes modal viewed' as const;

  constructor(
    public event_properties: CartChangesModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CartViewed implements BaseEvent {
  event_type = 'cart viewed' as const;

  constructor(
    public event_properties: CartViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CategoryPageViewed implements BaseEvent {
  event_type = 'category page viewed' as const;

  constructor(
    public event_properties: CategoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutAdditionalOrderClickGoToPayment implements BaseEvent {
  event_type = 'checkout additional order click go to payment' as const;

  constructor(
    public event_properties: CheckoutAdditionalOrderClickGoToPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutAdditionalOrderStarted implements BaseEvent {
  event_type = 'checkout additional order started' as const;

  constructor(
    public event_properties: CheckoutAdditionalOrderStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutAdditionalOrderViewed implements BaseEvent {
  event_type = 'checkout additional order viewed' as const;

  constructor(
    public event_properties: CheckoutAdditionalOrderViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutClickContinueShopping implements BaseEvent {
  event_type = 'checkout click continue shopping' as const;
}

export class CheckoutClickValidateMyInfo implements BaseEvent {
  event_type = 'checkout click validate my info' as const;
}

export class CheckoutCrossSellTriggered implements BaseEvent {
  event_type = 'checkout cross sell triggered' as const;
}

export class CheckoutCrossSellViewed implements BaseEvent {
  event_type = 'checkout cross sell viewed' as const;

  constructor(
    public event_properties: CheckoutCrossSellViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStarted implements BaseEvent {
  event_type = 'checkout started' as const;

  constructor(
    public event_properties: CheckoutStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStep1Viewed implements BaseEvent {
  event_type = 'checkout step 1 viewed' as const;

  constructor(
    public event_properties: CheckoutStep1ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStep2Viewed implements BaseEvent {
  event_type = 'checkout step 2 viewed' as const;

  constructor(
    public event_properties: CheckoutStep2ViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseAuthorizationLevel implements BaseEvent {
  event_type = 'choose authorization level' as const;

  constructor(
    public event_properties: ChooseAuthorizationLevelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClearTheCartClickCtaValidated implements BaseEvent {
  event_type = 'clear the cart click cta validated' as const;

  constructor(
    public event_properties: ClearTheCartClickCtaValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAddAllProductsToCart implements BaseEvent {
  event_type = 'click add all products to cart' as const;

  constructor(
    public event_properties: ClickAddAllProductsToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAddAllToCart implements BaseEvent {
  event_type = 'click add all to cart' as const;
}

export class ClickAddPortion implements BaseEvent {
  event_type = 'click add portion' as const;

  constructor(
    public event_properties: ClickAddPortionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAddToCart implements BaseEvent {
  event_type = 'click add to cart' as const;

  constructor(
    public event_properties: ClickAddToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAddToFavoritesList implements BaseEvent {
  event_type = 'click add to favorites list' as const;

  constructor(
    public event_properties: ClickAddToFavoritesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickBack implements BaseEvent {
  event_type = 'click back' as const;

  constructor(
    public event_properties: ClickBackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickBreadcrumbLevel0 implements BaseEvent {
  event_type = 'click breadcrumb level 0' as const;
}

export class ClickBreadcrumbLevel1 implements BaseEvent {
  event_type = 'click breadcrumb level 1' as const;
}

export class ClickBreadcrumbLevel2 implements BaseEvent {
  event_type = 'click breadcrumb level 2' as const;
}

export class ClickCardProduct implements BaseEvent {
  event_type = 'click card product' as const;

  constructor(
    public event_properties: ClickCardProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCardRecipe implements BaseEvent {
  event_type = 'click card recipe' as const;

  constructor(
    public event_properties: ClickCardRecipeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCgvCheckbox implements BaseEvent {
  event_type = 'click cgv checkbox' as const;

  constructor(
    public event_properties: ClickCgvCheckboxProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickClearTheCart implements BaseEvent {
  event_type = 'click clear the cart' as const;
}

export class ClickCloseTheChat implements BaseEvent {
  event_type = 'click close the chat' as const;
}

export class ClickConfirmReplacement implements BaseEvent {
  event_type = 'click confirm replacement' as const;

  constructor(
    public event_properties: ClickConfirmReplacementProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCouponTab implements BaseEvent {
  event_type = 'click coupon tab' as const;

  constructor(
    public event_properties: ClickCouponTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCta implements BaseEvent {
  event_type = 'click cta' as const;

  constructor(
    public event_properties?: ClickCtaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaCancelOrder implements BaseEvent {
  event_type = 'click cta cancel order' as const;
}

export class ClickCtaCompleteIncompleteProducts implements BaseEvent {
  event_type = 'click cta complete incomplete products' as const;

  constructor(
    public event_properties: ClickCtaCompleteIncompleteProductsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaEditCurrentOrder implements BaseEvent {
  event_type = 'click cta edit current order' as const;
}

export class ClickCtaEditOrder implements BaseEvent {
  event_type = 'click cta edit order' as const;
}

export class ClickCtaEditSlot implements BaseEvent {
  event_type = 'click cta edit slot' as const;

  constructor(
    public event_properties: ClickCtaEditSlotProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaEndTheFlow implements BaseEvent {
  event_type = 'click cta end the flow' as const;
}

export class ClickCtaGeolocation implements BaseEvent {
  event_type = 'click cta geolocation' as const;

  constructor(
    public event_properties: ClickCtaGeolocationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaMakeNewOrder implements BaseEvent {
  event_type = 'click cta make new order' as const;

  constructor(
    public event_properties: ClickCtaMakeNewOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaNextIncompleteProduct implements BaseEvent {
  event_type = 'click cta next incomplete product' as const;
}

export class ClickCtaNextOutOfStockProduct implements BaseEvent {
  event_type = 'click cta next out of stock product' as const;
}

export class ClickCtaOrderAgain implements BaseEvent {
  event_type = 'click cta order again' as const;
}

export class ClickCtaOrderDetails implements BaseEvent {
  event_type = 'click CTA order details' as const;

  constructor(
    public event_properties: ClickCtaOrderDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaOrderPreparationFeesInfo implements BaseEvent {
  event_type = 'click cta order preparation fees info' as const;
}

export class ClickCtaPreauthorizationFeesInfo implements BaseEvent {
  event_type = 'click cta preauthorization fees info' as const;
}

export class ClickCtaReplaceOutOfStockProducts implements BaseEvent {
  event_type = 'click cta replace out of stock products' as const;

  constructor(
    public event_properties: ClickCtaReplaceOutOfStockProductsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaSendMyEMail implements BaseEvent {
  event_type = 'click cta send my e-mail' as const;

  constructor(
    public event_properties?: ClickCtaSendMyEMailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaShowMoreProducts implements BaseEvent {
  event_type = 'click cta show more products' as const;

  constructor(
    public event_properties: ClickCtaShowMoreProductsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCtaSimilarProducts implements BaseEvent {
  event_type = 'click cta similar products' as const;

  constructor(
    public event_properties: ClickCtaSimilarProductsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDeleteProductsFromMyProducts implements BaseEvent {
  event_type = 'click delete products from my products' as const;
}

export class ClickIngredient implements BaseEvent {
  event_type = 'click ingredient' as const;
}

export class ClickIngredientItem implements BaseEvent {
  event_type = 'click ingredient item' as const;

  constructor(
    public event_properties: ClickIngredientItemProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickIngredientPicker implements BaseEvent {
  event_type = 'click ingredient picker' as const;

  constructor(
    public event_properties: ClickIngredientPickerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMegaMenuFamilyPicture implements BaseEvent {
  event_type = 'click mega menu family picture' as const;

  constructor(
    public event_properties: ClickMegaMenuFamilyPictureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMegaMenuReinsuranceFooter implements BaseEvent {
  event_type = 'click mega menu reinsurance footer' as const;

  constructor(
    public event_properties: ClickMegaMenuReinsuranceFooterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMyProductsIcon implements BaseEvent {
  event_type = 'click my products icon' as const;
}

export class ClickOnToasterToCancelDeletionFromFavorites implements BaseEvent {
  event_type = 'click on toaster to cancel deletion from favorites' as const;
}

export class ClickOnToasterToViewCart implements BaseEvent {
  event_type = 'click on toaster to view cart' as const;
}

export class ClickOpenReplacementOptions implements BaseEvent {
  event_type = 'click open replacement options' as const;

  constructor(
    public event_properties: ClickOpenReplacementOptionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickOpenTheChat implements BaseEvent {
  event_type = 'click open the chat' as const;
}

export class ClickOrderTrackingPanel implements BaseEvent {
  event_type = 'click order tracking panel' as const;

  constructor(
    public event_properties: ClickOrderTrackingPanelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPortionPickerIngredientsList implements BaseEvent {
  event_type = 'click portion picker ingredients list' as const;

  constructor(
    public event_properties: ClickPortionPickerIngredientsListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPrivacyPolicyCheckbox implements BaseEvent {
  event_type = 'click privacy policy checkbox' as const;

  constructor(
    public event_properties: ClickPrivacyPolicyCheckboxProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickProductDetails implements BaseEvent {
  event_type = 'click product details' as const;

  constructor(
    public event_properties: ClickProductDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickProductsInformationChangesTrackingPanel implements BaseEvent {
  event_type = 'click products information changes tracking panel' as const;

  constructor(
    public event_properties: ClickProductsInformationChangesTrackingPanelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRemoveFromFavoritesList implements BaseEvent {
  event_type = 'click remove from favorites list' as const;

  constructor(
    public event_properties: ClickRemoveFromFavoritesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRemovePortion implements BaseEvent {
  event_type = 'click remove portion' as const;
}

export class ClickRemoveProduct implements BaseEvent {
  event_type = 'click remove product' as const;

  constructor(
    public event_properties: ClickRemoveProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSearchResultsTab implements BaseEvent {
  event_type = 'click search results tab' as const;

  constructor(
    public event_properties: ClickSearchResultsTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSeveralOrdersTrackingPanel implements BaseEvent {
  event_type = 'click several orders tracking panel' as const;
}

export class ClickTrashBinDeleteTheProduct implements BaseEvent {
  event_type = 'click trash bin delete the product' as const;

  constructor(
    public event_properties: ClickTrashBinDeleteTheProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickTrustscoreFooter implements BaseEvent {
  event_type = 'click trustscore footer' as const;
}

export class CloseBurgerMenu implements BaseEvent {
  event_type = 'Close burger menu' as const;
}

export class CodeAccepted implements BaseEvent {
  event_type = 'code accepted' as const;

  constructor(
    public event_properties: CodeAcceptedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CodeApplied implements BaseEvent {
  event_type = 'code applied' as const;

  constructor(
    public event_properties: CodeAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CodeRejected implements BaseEvent {
  event_type = 'code rejected' as const;

  constructor(
    public event_properties: CodeRejectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyOfClickCtaNextOutOfStockProduct implements BaseEvent {
  event_type = 'Copy of click cta next out of stock product' as const;
}

export class CouponActivated implements BaseEvent {
  event_type = 'coupon activated' as const;

  constructor(
    public event_properties?: CouponActivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CouponDeactivated implements BaseEvent {
  event_type = 'coupon deactivated' as const;

  constructor(
    public event_properties?: CouponDeactivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CouponDisabled implements BaseEvent {
  event_type = 'coupon disabled' as const;

  constructor(
    public event_properties: CouponDisabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CouponModalViewed implements BaseEvent {
  event_type = 'coupon modal viewed' as const;
}

export class CreateAccountStarted implements BaseEvent {
  event_type = 'create account started' as const;

  constructor(
    public event_properties: CreateAccountStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccountSubmitted implements BaseEvent {
  event_type = 'create account submitted' as const;

  constructor(
    public event_properties: CreateAccountSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreditCardAdded implements BaseEvent {
  event_type = 'credit card added' as const;
}

export class DeleteProductsConfirmationModalViewed implements BaseEvent {
  event_type = 'delete products confirmation modal viewed' as const;
}

export class DeliveryTrackingModalClosed implements BaseEvent {
  event_type = 'delivery tracking modal closed' as const;

  constructor(
    public event_properties: DeliveryTrackingModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeliveryTrackingModalRefreshed implements BaseEvent {
  event_type = 'delivery tracking modal refreshed' as const;
}

export class DeliveryTrackingModalViewed implements BaseEvent {
  event_type = 'delivery tracking modal viewed' as const;
}

export class DidomiAction implements BaseEvent {
  event_type = 'didomi action' as const;

  constructor(
    public event_properties: DidomiActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditIngredientQuantity implements BaseEvent {
  event_type = 'edit ingredient quantity' as const;

  constructor(
    public event_properties: EditIngredientQuantityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditOrderClickCtaValidated implements BaseEvent {
  event_type = 'edit order click cta validated' as const;

  constructor(
    public event_properties: EditOrderClickCtaValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorModalView implements BaseEvent {
  event_type = 'error modal view' as const;

  constructor(
    public event_properties: ErrorModalViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorPayment implements BaseEvent {
  event_type = 'error payment' as const;

  constructor(
    public event_properties: ErrorPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FieldErrorViewed implements BaseEvent {
  event_type = 'field error viewed' as const;

  constructor(
    public event_properties: FieldErrorViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FrequentPurchasesPageViewed implements BaseEvent {
  event_type = 'frequent purchases page viewed' as const;

  constructor(
    public event_properties?: FrequentPurchasesPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderMarketingClick implements BaseEvent {
  event_type = 'header marketing click' as const;

  constructor(
    public event_properties: HeaderMarketingClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageViewed implements BaseEvent {
  event_type = 'homepage viewed' as const;

  constructor(
    public event_properties: HomepageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImpossibleAddProductsModalViewed implements BaseEvent {
  event_type = 'Impossible add products modal viewed' as const;

  constructor(
    public event_properties: ImpossibleAddProductsModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IngredientsSubstitutesModalViewed implements BaseEvent {
  event_type = 'ingredients substitutes modal viewed' as const;

  constructor(
    public event_properties?: IngredientsSubstitutesModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandingPageViewed implements BaseEvent {
  event_type = 'landing page viewed' as const;

  constructor(
    public event_properties: LandingPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LastOrderViewed implements BaseEvent {
  event_type = 'last order viewed' as const;

  constructor(
    public event_properties?: LastOrderViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LastOrdersPageViewed implements BaseEvent {
  event_type = 'last orders page viewed' as const;
}

export class LastminuteCrossSellPageViewed implements BaseEvent {
  event_type = 'lastminute cross sell page viewed' as const;

  constructor(
    public event_properties: LastminuteCrossSellPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedIn implements BaseEvent {
  event_type = 'logged in' as const;

  constructor(
    public event_properties?: LoggedInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedInStarted implements BaseEvent {
  event_type = 'logged in started' as const;

  constructor(
    public event_properties: LoggedInStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedInSubmitted implements BaseEvent {
  event_type = 'logged in submitted' as const;

  constructor(
    public event_properties: LoggedInSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedOut implements BaseEvent {
  event_type = 'logged out' as const;
}

export class MakeNewOrderModalViewed implements BaseEvent {
  event_type = 'make new order modal viewed' as const;

  constructor(
    public event_properties: MakeNewOrderModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketingModalViewed implements BaseEvent {
  event_type = 'marketing modal viewed' as const;

  constructor(
    public event_properties: MarketingModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketingPageViewed implements BaseEvent {
  event_type = 'marketing page viewed' as const;

  constructor(
    public event_properties: MarketingPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyProductsCategoryPageViewed implements BaseEvent {
  event_type = 'my products category page viewed' as const;

  constructor(
    public event_properties?: MyProductsCategoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MyProductsInformationsModalViewed implements BaseEvent {
  event_type = 'my products informations modal viewed' as const;

  constructor(
    public event_properties: MyProductsInformationsModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NextLastMinuteCrossSell implements BaseEvent {
  event_type = 'next last minute cross sell' as const;

  constructor(
    public event_properties: NextLastMinuteCrossSellProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenBurgerMenu implements BaseEvent {
  event_type = 'Open burger menu' as const;
}

export class OptInToggleClicked implements BaseEvent {
  event_type = 'opt-in toggle clicked' as const;

  constructor(
    public event_properties: OptInToggleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderCancelled implements BaseEvent {
  event_type = 'order cancelled' as const;

  constructor(
    public event_properties: OrderCancelledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderCompleted implements BaseEvent {
  event_type = 'order completed' as const;

  constructor(
    public event_properties: OrderCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderDeliveryInformationReceived implements BaseEvent {
  event_type = 'order delivery information received' as const;

  constructor(
    public event_properties: OrderDeliveryInformationReceivedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderReviewed implements BaseEvent {
  event_type = 'order reviewed' as const;

  constructor(
    public event_properties?: OrderReviewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderSubmitted implements BaseEvent {
  event_type = 'order submitted' as const;

  constructor(
    public event_properties: OrderSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PackingReductionToggleClick implements BaseEvent {
  event_type = 'packing reduction toggle click' as const;

  constructor(
    public event_properties: PackingReductionToggleClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentInfoCompleted implements BaseEvent {
  event_type = 'payment info completed' as const;

  constructor(
    public event_properties: PaymentInfoCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PhotoModalViewed implements BaseEvent {
  event_type = 'photo modal viewed' as const;

  constructor(
    public event_properties?: PhotoModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProductDeletedFromMyProducts implements BaseEvent {
  event_type = 'product deleted from my products' as const;
}

export class ProductPageViewed implements BaseEvent {
  event_type = 'product page viewed' as const;

  constructor(
    public event_properties: ProductPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReadLess implements BaseEvent {
  event_type = 'read less' as const;

  constructor(
    public event_properties?: ReadLessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReadMore implements BaseEvent {
  event_type = 'read more' as const;

  constructor(
    public event_properties?: ReadMoreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecipePageViewed implements BaseEvent {
  event_type = 'recipe page viewed' as const;

  constructor(
    public event_properties: RecipePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReferralCodeCopied implements BaseEvent {
  event_type = 'referral code copied' as const;

  constructor(
    public event_properties: ReferralCodeCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReferralCodeShared implements BaseEvent {
  event_type = 'referral code shared' as const;

  constructor(
    public event_properties: ReferralCodeSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReferralModalViewed implements BaseEvent {
  event_type = 'referral modal viewed' as const;

  constructor(
    public event_properties: ReferralModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RollOutPhotoCarousel implements BaseEvent {
  event_type = 'roll out photo carousel' as const;

  constructor(
    public event_properties: RollOutPhotoCarouselProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RootPageViewed implements BaseEvent {
  event_type = 'root page viewed' as const;

  constructor(
    public event_properties: RootPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScrollHorizontalList implements BaseEvent {
  event_type = 'scroll horizontal list' as const;

  constructor(
    public event_properties?: ScrollHorizontalListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchCompleted implements BaseEvent {
  event_type = 'search completed' as const;

  constructor(
    public event_properties: SearchCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchStarted implements BaseEvent {
  event_type = 'search started' as const;

  constructor(
    public event_properties?: SearchStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendProposal implements BaseEvent {
  event_type = 'send proposal' as const;

  constructor(
    public event_properties: SendProposalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendSmsCode implements BaseEvent {
  event_type = 'Send sms code' as const;

  constructor(
    public event_properties?: SendSmsCodeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingCtaClick implements BaseEvent {
  event_type = 'shipping cta click' as const;

  constructor(
    public event_properties: ShippingCtaClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingInformationModalViewed implements BaseEvent {
  event_type = 'shipping information modal viewed' as const;

  constructor(
    public event_properties: ShippingInformationModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotClosed implements BaseEvent {
  event_type = 'shipping slot closed' as const;
}

export class ShippingSlotDayViewed implements BaseEvent {
  event_type = 'shipping slot day viewed' as const;

  constructor(
    public event_properties?: ShippingSlotDayViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotStartUpdate implements BaseEvent {
  event_type = 'shipping slot start update' as const;
}

export class ShippingSlotStarted implements BaseEvent {
  event_type = 'shipping slot started' as const;

  constructor(
    public event_properties: ShippingSlotStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotToasterClick implements BaseEvent {
  event_type = 'shipping slot toaster click' as const;
}

export class ShippingSlotToasterViewed implements BaseEvent {
  event_type = 'shipping slot toaster viewed' as const;

  constructor(
    public event_properties?: ShippingSlotToasterViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotUpdated implements BaseEvent {
  event_type = 'shipping slot updated' as const;

  constructor(
    public event_properties: ShippingSlotUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotValidated implements BaseEvent {
  event_type = 'shipping slot validated' as const;

  constructor(
    public event_properties: ShippingSlotValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingSlotViewed implements BaseEvent {
  event_type = 'shipping slot viewed' as const;
}

export class ShippingTypeRefused implements BaseEvent {
  event_type = 'shipping type refused' as const;

  constructor(
    public event_properties: ShippingTypeRefusedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingTypeStarted implements BaseEvent {
  event_type = 'shipping type started' as const;

  constructor(
    public event_properties: ShippingTypeStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingTypeSubmitted implements BaseEvent {
  event_type = 'shipping type submitted' as const;

  constructor(
    public event_properties: ShippingTypeSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingTypeValidated implements BaseEvent {
  event_type = 'shipping type validated' as const;

  constructor(
    public event_properties: ShippingTypeValidatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShippingTypeViewed implements BaseEvent {
  event_type = 'shipping type viewed' as const;

  constructor(
    public event_properties: ShippingTypeViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SimilarProductsListViewed implements BaseEvent {
  event_type = 'similar products list viewed' as const;

  constructor(
    public event_properties?: SimilarProductsListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SmsCodeAccepted implements BaseEvent {
  event_type = 'Sms code accepted' as const;

  constructor(
    public event_properties?: SmsCodeAcceptedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SmsCodeRefused implements BaseEvent {
  event_type = 'Sms code refused' as const;

  constructor(
    public event_properties?: SmsCodeRefusedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartProposal implements BaseEvent {
  event_type = 'start proposal' as const;
}

export class SubcategoryClickTitle implements BaseEvent {
  event_type = 'subcategory click title' as const;

  constructor(
    public event_properties?: SubcategoryClickTitleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubcategoryPageViewed implements BaseEvent {
  event_type = 'subcategory page viewed' as const;

  constructor(
    public event_properties: SubcategoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubcategoryViewed implements BaseEvent {
  event_type = 'subcategory viewed' as const;

  constructor(
    public event_properties: SubcategoryViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnavailableProductsReplacementInformationClick implements BaseEvent {
  event_type = 'unavailable products replacement information click' as const;
}

export class UnavailableProductsReplacementToggleClick implements BaseEvent {
  event_type = 'unavailable products replacement toggle click' as const;

  constructor(
    public event_properties: UnavailableProductsReplacementToggleClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationBasket implements BaseEvent {
  event_type = 'user navigation basket' as const;
}

export class UserNavigationCardCategory implements BaseEvent {
  event_type = 'user navigation card category' as const;

  constructor(
    public event_properties: UserNavigationCardCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationFooter implements BaseEvent {
  event_type = 'user navigation footer' as const;

  constructor(
    public event_properties?: UserNavigationFooterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationHeader implements BaseEvent {
  event_type = 'user navigation header' as const;

  constructor(
    public event_properties?: UserNavigationHeaderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenu implements BaseEvent {
  event_type = 'user navigation menu' as const;

  constructor(
    public event_properties?: UserNavigationMenuProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenuCategory implements BaseEvent {
  event_type = 'user navigation menu category' as const;

  constructor(
    public event_properties?: UserNavigationMenuCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenuFamily implements BaseEvent {
  event_type = 'user navigation menu family' as const;

  constructor(
    public event_properties: UserNavigationMenuFamilyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenuMyProductsCategory implements BaseEvent {
  event_type = 'user navigation menu my products category' as const;

  constructor(
    public event_properties?: UserNavigationMenuMyProductsCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenuPortal implements BaseEvent {
  event_type = 'user navigation menu portal' as const;

  constructor(
    public event_properties?: UserNavigationMenuPortalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserNavigationMenuSubcategory implements BaseEvent {
  event_type = 'user navigation menu subcategory' as const;

  constructor(
    public event_properties?: UserNavigationMenuSubcategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserPortalClickContinueShopping implements BaseEvent {
  event_type = 'user portal click continue shopping' as const;
}

export class UserPortalClickReferralCard implements BaseEvent {
  event_type = 'user portal click referral card' as const;
}

export class UserPortalViewed implements BaseEvent {
  event_type = 'user portal viewed' as const;

  constructor(
    public event_properties: UserPortalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewAllReviews implements BaseEvent {
  event_type = 'view all reviews' as const;

  constructor(
    public event_properties: ViewAllReviewsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewFavoritesList implements BaseEvent {
  event_type = 'view favorites list' as const;

  constructor(
    public event_properties: ViewFavoritesListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSearchResults implements BaseEvent {
  event_type = 'view search results' as const;

  constructor(
    public event_properties: ViewSearchResultsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSuggestionsResults implements BaseEvent {
  event_type = 'view suggestions results' as const;

  constructor(
    public event_properties: ViewSuggestionsResultsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Attribution Captured
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/%5BAmplitude%5D%20Attribution%20Captured)
   *
   * This event is automatically captured by Amplitude and detect for each web session, fclid, gclid, utms and referrers if exists. It is used to capture session attribution.
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. channel)
   * @param options Amplitude event options.
   */
  amplitudeAttributionCaptured(
    properties?: AmplitudeAttributionCapturedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeAttributionCaptured(properties), options);
  }

  /**
   * account created
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/account%20created)
   *
   * Quand le nouveau compte utilisateur est créé.
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties?: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * account modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/account%20modified)
   *
   * Quand l'utilisateur modifie les informations de son compte 
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. verification method)
   * @param options Amplitude event options.
   */
  accountModified(
    properties?: AccountModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountModified(properties), options);
  }

  /**
   * add credit card
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/add%20credit%20card)
   *
   * Lorsqu'une nouvelle carte de crédit est ajoutée sur le compte du client
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  addCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new AddCreditCard(), options);
  }

  /**
   * add ingredients page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/add%20ingredients%20page%20viewed)
   *
   * Quand l'utilisateur voit la liste des ingrédients d'une recette. 
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. in stock products)
   * @param options Amplitude event options.
   */
  addIngredientsPageViewed(
    properties: AddIngredientsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddIngredientsPageViewed(properties), options);
  }

  /**
   * add ingredients to cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/add%20ingredients%20to%20cart)
   *
   * Lorsque l'utilisateur clique sur "Ajouter au panier" depuis la liste des ingrédients d'une recette.
   *
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. click location)
   * @param options Amplitude event options.
   */
  addIngredientsToCart(
    properties: AddIngredientsToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddIngredientsToCart(properties), options);
  }

  /**
   * additional order completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/additional%20order%20completed)
   *
   * Lorsque le complément decommande de l'utilisateur est traité avec succès et que le prépaiement  du complément de commande est effectué
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  additionalOrderCompleted(
    properties: AdditionalOrderCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdditionalOrderCompleted(properties), options);
  }

  /**
   * additional order submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/additional%20order%20submitted)
   *
   * Quand l’utilisateur clique sur “Payer ma commande” pour un complément de commande
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  additionalOrderSubmitted(
    properties: AdditionalOrderSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdditionalOrderSubmitted(properties), options);
  }

  /**
   * brand page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/brand%20page%20viewed)
   *
   * Quand l'utilisateur voit une page marketing telle que "Nos engagements", "Qui sommes nous" etc.. Actuellement (02/24), toutes les pages Builder.io remontent avec cet event.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  brandPageViewed(
    properties: BrandPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandPageViewed(properties), options);
  }

  /**
   * card marketing click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/card%20marketing%20click)
   *
   * Quand l'utilisateur clique sur une card Marketing.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. card position in the list)
   * @param options Amplitude event options.
   */
  cardMarketingClick(
    properties: CardMarketingClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardMarketingClick(properties), options);
  }

  /**
   * cart changes modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/cart%20changes%20modal%20viewed)
   *
   * Quand l'utilisateur tente de payer sa commande mais qu'un produit a été modifié, n'est plus dispo ou est incomplet, entre temps.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. number of products affected by a change)
   * @param options Amplitude event options.
   */
  cartChangesModalViewed(
    properties: CartChangesModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CartChangesModalViewed(properties), options);
  }

  /**
   * cart viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/cart%20viewed)
   *
   * Quand le User voit son panier
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  cartViewed(
    properties: CartViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CartViewed(properties), options);
  }

  /**
   * category page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/category%20page%20viewed)
   *
   * Quand l'utilisateur affiche la page d'une catégorie de produits.
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. category position)
   * @param options Amplitude event options.
   */
  categoryPageViewed(
    properties: CategoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategoryPageViewed(properties), options);
  }

  /**
   * checkout additional order click go to payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20additional%20order%20click%20go%20to%20payment)
   *
   * Quand l’utilisateur clique sur “Passer au paiement, dans le checkout d’un complément de commande
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutAdditionalOrderClickGoToPayment(
    properties: CheckoutAdditionalOrderClickGoToPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutAdditionalOrderClickGoToPayment(properties), options);
  }

  /**
   * checkout additional order started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20additional%20order%20started)
   *
   * Quand l’utilisateur clique sur “Compléter ma commande” (panier du complément de commande)
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutAdditionalOrderStarted(
    properties: CheckoutAdditionalOrderStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutAdditionalOrderStarted(properties), options);
  }

  /**
   * checkout additional order viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20additional%20order%20viewed)
   *
   * Quand l'utilisateur voit le chekout du complément de commande alors
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutAdditionalOrderViewed(
    properties: CheckoutAdditionalOrderViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutAdditionalOrderViewed(properties), options);
  }

  /**
   * checkout click continue shopping
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20click%20continue%20shopping)
   *
   * Quand l'utilisateur clic sur "continuer mes achats" dans le checkout
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  checkoutClickContinueShopping(
    options?: EventOptions,
  ) {
    return this.track(new CheckoutClickContinueShopping(), options);
  }

  /**
   * checkout click validate my info
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20click%20validate%20my%20info)
   *
   * Quand l'utilisateur click sur le CTA "Valider mes informations" dans le checkout
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  checkoutClickValidateMyInfo(
    options?: EventOptions,
  ) {
    return this.track(new CheckoutClickValidateMyInfo(), options);
  }

  /**
   * checkout cross sell triggered
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20cross%20sell%20triggered)
   *
   * Est déclenché lorsque le cross-sell du panier est déclenché et affiché à l'utilisateur sur ECOM.
   *
   *
   * @param options Amplitude event options.
   */
  checkoutCrossSellTriggered(
    options?: EventOptions,
  ) {
    return this.track(new CheckoutCrossSellTriggered(), options);
  }

  /**
   * checkout cross sell viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20cross%20sell%20viewed)
   *
   * Lorsque l'utilisateur voit la bannière cross sell à coté du panier (web) ou sous le panier (mobile)
   *
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  checkoutCrossSellViewed(
    properties: CheckoutCrossSellViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutCrossSellViewed(properties), options);
  }

  /**
   * checkout started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20started)
   *
   * Quand le user arrive sur le checkout
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutStarted(
    properties: CheckoutStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStarted(properties), options);
  }

  /**
   * checkout step 1 viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20step%201%20viewed)
   *
   * Quand l'utilisateur voit la première étape du chekout (Informations)
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutStep1Viewed(
    properties: CheckoutStep1ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStep1Viewed(properties), options);
  }

  /**
   * checkout step 2 viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/checkout%20step%202%20viewed)
   *
   * Quand le user voit la seconde étape du checkout (paiement)
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  checkoutStep2Viewed(
    properties: CheckoutStep2ViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStep2Viewed(properties), options);
  }

  /**
   * choose authorization level
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/choose%20authorization%20level)
   *
   * Quand l'utilisateur s'opt-in aux mails ou SMS.
   *
   * L'event peut donc être envoyé à différent moment :
   *
   * * Lors de l'inscription
   *
   * * Lorsque l'utilistaeur modifie ses préférences
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. authorization level)
   * @param options Amplitude event options.
   */
  chooseAuthorizationLevel(
    properties: ChooseAuthorizationLevelProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseAuthorizationLevel(properties), options);
  }

  /**
   * clear the cart click cta validated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/clear%20the%20cart%20click%20cta%20validated)
   *
   * Juste après l'event "clear the cart", quand l'utilisateur valide la suppression de tous les articles de son panier.
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  clearTheCartClickCtaValidated(
    properties: ClearTheCartClickCtaValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClearTheCartClickCtaValidated(properties), options);
  }

  /**
   * click add all products to cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20add%20all%20products%20to%20cart)
   *
   * Lorsque l'utilisateur clique sur le bouton "Ajouter tous les produits à mon panier" depuis :
   *
   * * Sa liste de favoris
   *
   * * La lsite des produits d'une commande passée
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickAddAllProductsToCart(
    properties: ClickAddAllProductsToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAddAllProductsToCart(properties), options);
  }

  /**
   * click add all to cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20add%20all%20to%20cart)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickAddAllToCart(
    options?: EventOptions,
  ) {
    return this.track(new ClickAddAllToCart(), options);
  }

  /**
   * click add portion
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20add%20portion)
   *
   * Clic sur le "+" du picker de portions
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. click location)
   * @param options Amplitude event options.
   */
  clickAddPortion(
    properties: ClickAddPortionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAddPortion(properties), options);
  }

  /**
   * click add to cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20add%20to%20cart)
   *
   * Quand l'utilisateur ajoute un produit au panier. 
   *  L'évenement est déclenché à chaque clic pour ajouter une qté d'un article.  
   *  Pour savoir si l'article a bien été ajouté au panier (ATC), il faut filtrer sur la propriété "add or remove status"= "product added"
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickAddToCart(
    properties: ClickAddToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAddToCart(properties), options);
  }

  /**
   * click add to favorites list
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20add%20to%20favorites%20list)
   *
   * Quand l'utilisateur ajoute un produit aux favoris (En cliquant sur le coeur d'un depuis la fiche produit, la card produit ou depuis une liste de produits).
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickAddToFavoritesList(
    properties: ClickAddToFavoritesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAddToFavoritesList(properties), options);
  }

  /**
   * click back
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20back)
   *
   * Lors du clic sur le bouton back sur ECOM only des fiches produits et recettes
   *
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  clickBack(
    properties: ClickBackProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickBack(properties), options);
  }

  /**
   * click breadcrumb level 0
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20breadcrumb%20level%200)
   *
   * ECOM uniquement : Depuis une fiche produit, quand l'utilisateur clique sur le 1er niveau deu fil d'ariane.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickBreadcrumbLevel0(
    options?: EventOptions,
  ) {
    return this.track(new ClickBreadcrumbLevel0(), options);
  }

  /**
   * click breadcrumb level 1
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20breadcrumb%20level%201)
   *
   * ECOM uniquement : Depuis une fiche produit, quand l'utilisateur clique sur le 2ème niveau deu fil d'ariane.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickBreadcrumbLevel1(
    options?: EventOptions,
  ) {
    return this.track(new ClickBreadcrumbLevel1(), options);
  }

  /**
   * click breadcrumb level 2
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20breadcrumb%20level%202)
   *
   * ECOM uniquement : Depuis une fiche produit, quand l'utilisateur clique sur le 3ème niveau deu fil d'ariane.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickBreadcrumbLevel2(
    options?: EventOptions,
  ) {
    return this.track(new ClickBreadcrumbLevel2(), options);
  }

  /**
   * click card product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20card%20product)
   *
   * Lorsqu'un user clic sur une card produit
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  clickCardProduct(
    properties: ClickCardProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCardProduct(properties), options);
  }

  /**
   * click card recipe
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20card%20recipe)
   *
   * Quand l'utilisateur clique sur une carte Recette.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. card position in the list)
   * @param options Amplitude event options.
   */
  clickCardRecipe(
    properties: ClickCardRecipeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCardRecipe(properties), options);
  }

  /**
   * click cgv checkbox
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cgv%20checkbox)
   *
   * Quand l'utilisateur clique sur la case à cocher d'acceptation des CGV (peut être depuis le checkout, comme l'inscription).
   *
   *
   *
   *
   * @param properties The event's properties (e.g. authorization level)
   * @param options Amplitude event options.
   */
  clickCgvCheckbox(
    properties: ClickCgvCheckboxProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCgvCheckbox(properties), options);
  }

  /**
   * click clear the cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20clear%20the%20cart)
   *
   * Quand l'utilisateur clique sur le bouton "Tout supprimer" depuis le panier (le bouton affiche une modale de confirmation).
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickClearTheCart(
    options?: EventOptions,
  ) {
    return this.track(new ClickClearTheCart(), options);
  }

  /**
   * click close the chat
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20close%20the%20chat)
   *
   * ECOM uniquement : Quand l'utilisateur ferme le chat.
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickCloseTheChat(
    options?: EventOptions,
  ) {
    return this.track(new ClickCloseTheChat(), options);
  }

  /**
   * click confirm replacement
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20confirm%20replacement)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. initial product name)
   * @param options Amplitude event options.
   */
  clickConfirmReplacement(
    properties: ClickConfirmReplacementProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickConfirmReplacement(properties), options);
  }

  /**
   * click coupon tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20coupon%20tab)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  clickCouponTab(
    properties: ClickCouponTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCouponTab(properties), options);
  }

  /**
   * click cta
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta)
   *
   * Clic sur un CTA standard.
   *
   *
   * Voir la propriété "cta button text" pour connaitre le nom du CTA
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cta button text)
   * @param options Amplitude event options.
   */
  clickCta(
    properties?: ClickCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCta(properties), options);
  }

  /**
   * click cta cancel order
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20cancel%20order)
   *
   * Quand le user clic sur le CTA pour Annuler sa commande
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaCancelOrder(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaCancelOrder(), options);
  }

  /**
   * click cta complete incomplete products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20complete%20incomplete%20products)
   *
   * Quand l'utilisateur clique sur le bouton "Compléter" depuis son panier, lorsque des produits sont incomplets.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. number of products to be completed)
   * @param options Amplitude event options.
   */
  clickCtaCompleteIncompleteProducts(
    properties: ClickCtaCompleteIncompleteProductsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaCompleteIncompleteProducts(properties), options);
  }

  /**
   * click cta edit current order
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20edit%20current%20order)
   *
   * Quand l'utilisateur clique sur "Modifier ma commande" depuis une card commande
   *
   * @param options Amplitude event options.
   */
  clickCtaEditCurrentOrder(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaEditCurrentOrder(), options);
  }

  /**
   * click cta edit order
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20edit%20order)
   *
   * Quand le user clic sur le CTA pour modifier sa commande
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaEditOrder(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaEditOrder(), options);
  }

  /**
   * click cta edit slot
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20edit%20slot)
   *
   * Lorsque l'utilisateur clique sur le bouton d'une commande existante pour modifier le créneau
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  clickCtaEditSlot(
    properties: ClickCtaEditSlotProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaEditSlot(properties), options);
  }

  /**
   * click cta end the flow
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20end%20the%20flow)
   *
   * Quand l'utilisateur clique sur le bouton "Terminer" à la fin du tunnel de remplacement des produits incomplets.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaEndTheFlow(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaEndTheFlow(), options);
  }

  /**
   * click cta geolocation
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20geolocation)
   *
   * Lorsque l'utilisateur clique sur la cible de géolocalisation
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  clickCtaGeolocation(
    properties: ClickCtaGeolocationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaGeolocation(properties), options);
  }

  /**
   * click cta make new order
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20make%20new%20order)
   *
   * Complément de commande - Quand le client clique sur "Faire une nouvelle commande" sur la modale "Voulez-vous passer une nouvelle commande ?"
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  clickCtaMakeNewOrder(
    properties: ClickCtaMakeNewOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaMakeNewOrder(properties), options);
  }

  /**
   * click cta next incomplete product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20next%20incomplete%20product)
   *
   * Quand un utilisateur a plusieurs produits incomplets et qu'il rentre dans le tunnel de complétion, l'event est envoyé lors du clic sur le bouton "Produit incomplet suivant".
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaNextIncompleteProduct(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaNextIncompleteProduct(), options);
  }

  /**
   * click cta next out of stock product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20next%20out%20of%20stock%20product)
   *
   * Quand un utilisateur a plusieurs produits indisponibles et qu'il rentre dans le tunnel de complétion, l'event est envoyé lors du clic sur le bouton "Produit indisponible suivant".
   *
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaNextOutOfStockProduct(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaNextOutOfStockProduct(), options);
  }

  /**
   * click cta order again
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20order%20again)
   *
   * Quand l'utilisateur clique sur "Commander à nouveau" depuis une card commande
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickCtaOrderAgain(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaOrderAgain(), options);
  }

  /**
   * click CTA order details
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20CTA%20order%20details)
   *
   * Depuis la modale de suivi de commande, quand l'utilisateur clique sur le bouton "Voir ma commande".
   *
   *
   *
   *
   * @param properties The event's properties (e.g. estimated arrival)
   * @param options Amplitude event options.
   */
  clickCtaOrderDetails(
    properties: ClickCtaOrderDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaOrderDetails(properties), options);
  }

  /**
   * click cta order preparation fees info
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20order%20preparation%20fees%20info)
   *
   * When the user clicks on the "i" at the checkout to get more infos about the preparation fees.
   *
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  clickCtaOrderPreparationFeesInfo(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaOrderPreparationFeesInfo(), options);
  }

  /**
   * click cta preauthorization fees info
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20preauthorization%20fees%20info)
   *
   * When the user clicks on the "information" icon in the checkout to learn more about preauthorization fees.
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickCtaPreauthorizationFeesInfo(
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaPreauthorizationFeesInfo(), options);
  }

  /**
   * click cta replace out of stock products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20replace%20out%20of%20stock%20products)
   *
   * Quand l'utilisateur clique sur le bouton "Remplacer" depuis le panier dans la section des produits indisponibles.
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. number of products to be replaced)
   * @param options Amplitude event options.
   */
  clickCtaReplaceOutOfStockProducts(
    properties: ClickCtaReplaceOutOfStockProductsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaReplaceOutOfStockProducts(properties), options);
  }

  /**
   * click cta send my e-mail
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20send%20my%20e-mail)
   *
   * Lorsque le user clic sur le CTA permettant d'ajouter son email dans la liste d'attente en cas d'adresse non livrable actuellement
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  clickCtaSendMyEMail(
    properties?: ClickCtaSendMyEMailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaSendMyEMail(properties), options);
  }

  /**
   * click cta show more products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20show%20more%20products)
   *
   * Quand l'utilisateur clique sur le bouton "Voir plus" sur une liste de produits similaires.
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. product id)
   * @param options Amplitude event options.
   */
  clickCtaShowMoreProducts(
    properties: ClickCtaShowMoreProductsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaShowMoreProducts(properties), options);
  }

  /**
   * click cta similar products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20cta%20similar%20products)
   *
   * Quand l'utilisateur clique sur le bouton pour voir les produits similaire en cas de produit indispo.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. initial product name)
   * @param options Amplitude event options.
   */
  clickCtaSimilarProducts(
    properties: ClickCtaSimilarProductsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCtaSimilarProducts(properties), options);
  }

  /**
   * click delete products from my products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20delete%20products%20from%20my%20products)
   *
   * Quand l’utilisateur clique sur l’icône “Supprimer” d’une card produit.
   *
   * @param options Amplitude event options.
   */
  clickDeleteProductsFromMyProducts(
    options?: EventOptions,
  ) {
    return this.track(new ClickDeleteProductsFromMyProducts(), options);
  }

  /**
   * click ingredient
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20ingredient)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickIngredient(
    options?: EventOptions,
  ) {
    return this.track(new ClickIngredient(), options);
  }

  /**
   * click ingredient item
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20ingredient%20item)
   *
   * \*\*Clic sur un produit de la liste des ingrédients principaux
   *
   * \*\*
   *
   *
   *
   *
   * @param properties The event's properties (e.g. product event source)
   * @param options Amplitude event options.
   */
  clickIngredientItem(
    properties: ClickIngredientItemProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickIngredientItem(properties), options);
  }

  /**
   * click ingredient picker
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20ingredient%20picker)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickIngredientPicker(
    properties: ClickIngredientPickerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickIngredientPicker(properties), options);
  }

  /**
   * click mega menu family picture
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20mega%20menu%20family%20picture)
   *
   * When the user clicks on the family picture in the mega menu
   *
   * @param properties The event's properties (e.g. family id)
   * @param options Amplitude event options.
   */
  clickMegaMenuFamilyPicture(
    properties: ClickMegaMenuFamilyPictureProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickMegaMenuFamilyPicture(properties), options);
  }

  /**
   * click mega menu reinsurance footer
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20mega%20menu%20reinsurance%20footer)
   *
   * When the user clicks on the reinsurance footer in the mega menu
   *
   *
   *
   *
   * @param properties The event's properties (e.g. family id)
   * @param options Amplitude event options.
   */
  clickMegaMenuReinsuranceFooter(
    properties: ClickMegaMenuReinsuranceFooterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickMegaMenuReinsuranceFooter(properties), options);
  }

  /**
   * click my products icon
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20my%20products%20icon)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickMyProductsIcon(
    options?: EventOptions,
  ) {
    return this.track(new ClickMyProductsIcon(), options);
  }

  /**
   * click on toaster to cancel deletion from favorites
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20on%20toaster%20to%20cancel%20deletion%20from%20favorites)
   *
   * Quand le user clic sur "Annuler" sur le toaster qui indique que le produit a bien été supprimé des favoris (et donc, le produit est remis aussitôt en favoris) 
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickOnToasterToCancelDeletionFromFavorites(
    options?: EventOptions,
  ) {
    return this.track(new ClickOnToasterToCancelDeletionFromFavorites(), options);
  }

  /**
   * click on toaster to view cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20on%20toaster%20to%20view%20cart)
   *
   * Quand un produit est ajouté au panier depuis la liste des commandes et des favoris, un toaster de confirmation est affiché. Cet évènement est déclenché quand un user clics sur "Voir mon panier"
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickOnToasterToViewCart(
    options?: EventOptions,
  ) {
    return this.track(new ClickOnToasterToViewCart(), options);
  }

  /**
   * click open replacement options
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20open%20replacement%20options)
   *
   * Clic Remplacer un produit depuis la liste des ingrédients principaux
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. product event source)
   * @param options Amplitude event options.
   */
  clickOpenReplacementOptions(
    properties: ClickOpenReplacementOptionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickOpenReplacementOptions(properties), options);
  }

  /**
   * click open the chat
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20open%20the%20chat)
   *
   * ECOM uniquement, quand l'utilisateur clique surle bouton en bas à gauche de l'écran.
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickOpenTheChat(
    options?: EventOptions,
  ) {
    return this.track(new ClickOpenTheChat(), options);
  }

  /**
   * click order tracking panel
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20order%20tracking%20panel)
   *
   * Lorsque l'utilisateur clique sur le bandeau "Commande en cours" depuis la homepage.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  clickOrderTrackingPanel(
    properties: ClickOrderTrackingPanelProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickOrderTrackingPanel(properties), options);
  }

  /**
   * click portion picker ingredients list
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20portion%20picker%20ingredients%20list)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. portion quantity)
   * @param options Amplitude event options.
   */
  clickPortionPickerIngredientsList(
    properties: ClickPortionPickerIngredientsListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPortionPickerIngredientsList(properties), options);
  }

  /**
   * click privacy policy checkbox
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20privacy%20policy%20checkbox)
   *
   * Quand l'utilisateur clique sur la checkbox des CGV (depuis le checkout, ou depuis l'inscription).
   *
   *
   *
   *
   * @param properties The event's properties (e.g. authorization level)
   * @param options Amplitude event options.
   */
  clickPrivacyPolicyCheckbox(
    properties: ClickPrivacyPolicyCheckboxProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPrivacyPolicyCheckbox(properties), options);
  }

  /**
   * click product details
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20product%20details)
   *
   * Event lorsque je clique sur l'encre "Détails du produit" dans une fiche produit
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. product id)
   * @param options Amplitude event options.
   */
  clickProductDetails(
    properties: ClickProductDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickProductDetails(properties), options);
  }

  /**
   * click products information changes tracking panel
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20products%20information%20changes%20tracking%20panel)
   *
   * Quand l'utilisateur clique sur le bandeau indiquant des modifications sur un ou plusieurs produits depuis le Panier.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. number of products affected by a change)
   * @param options Amplitude event options.
   */
  clickProductsInformationChangesTrackingPanel(
    properties: ClickProductsInformationChangesTrackingPanelProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickProductsInformationChangesTrackingPanel(properties), options);
  }

  /**
   * click remove from favorites list
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20remove%20from%20favorites%20list)
   *
   * Quand l'utilisateur supprime un favori (En cliquant sur le coeur d'un produit aux favoris depuis la fiche produit, la card produit ou depuis une liste de produits).
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickRemoveFromFavoritesList(
    properties: ClickRemoveFromFavoritesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRemoveFromFavoritesList(properties), options);
  }

  /**
   * click remove portion
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20remove%20portion)
   *
   * clic sur le "-" au niveau du picker de portions
   *
   * @param options Amplitude event options.
   */
  clickRemovePortion(
    options?: EventOptions,
  ) {
    return this.track(new ClickRemovePortion(), options);
  }

  /**
   * click remove product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20remove%20product)
   *
   * Quand l'utilisateur supprime une quantité d'un produit.
   *
   * Il est également envoyé en même temps que l'event  "click trash bin delete the product".
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. add or remove status)
   * @param options Amplitude event options.
   */
  clickRemoveProduct(
    properties: ClickRemoveProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRemoveProduct(properties), options);
  }

  /**
   * click search results tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20search%20results%20tab)
   *
   * Quand l'utilisateur clique sur un bouton "Produits" ou "Recettes" depuis les résultats de recherche.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  clickSearchResultsTab(
    properties: ClickSearchResultsTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSearchResultsTab(properties), options);
  }

  /**
   * click several orders tracking panel
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20several%20orders%20tracking%20panel)
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  clickSeveralOrdersTrackingPanel(
    options?: EventOptions,
  ) {
    return this.track(new ClickSeveralOrdersTrackingPanel(), options);
  }

  /**
   * click trash bin delete the product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20trash%20bin%20delete%20the%20product)
   *
   * Quand l'utilisateur clique sur l'icône "Corbeille" pour supprimer un produit depuis le Panier.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart stock status)
   * @param options Amplitude event options.
   */
  clickTrashBinDeleteTheProduct(
    properties: ClickTrashBinDeleteTheProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickTrashBinDeleteTheProduct(properties), options);
  }

  /**
   * click trustscore footer
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/click%20trustscore%20footer)
   *
   * Lorsque le user clic sur le widget de Trustscore dans le footer.
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickTrustscoreFooter(
    options?: EventOptions,
  ) {
    return this.track(new ClickTrustscoreFooter(), options);
  }

  /**
   * Close burger menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Close%20burger%20menu)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  closeBurgerMenu(
    options?: EventOptions,
  ) {
    return this.track(new CloseBurgerMenu(), options);
  }

  /**
   * code accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/code%20accepted)
   *
   * Quand le code saisie par l'utilisateur est validé car il remplie toutes les conditions.
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  codeAccepted(
    properties: CodeAcceptedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CodeAccepted(properties), options);
  }

  /**
   * code applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/code%20applied)
   *
   * Quand l'utilisateur valide un code promo
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  codeApplied(
    properties: CodeAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CodeApplied(properties), options);
  }

  /**
   * code rejected
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/code%20rejected)
   *
   * Quand le code saisie par le user est refusé
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. code)
   * @param options Amplitude event options.
   */
  codeRejected(
    properties: CodeRejectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CodeRejected(properties), options);
  }

  /**
   * Copy of click cta next out of stock product
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Copy%20of%20click%20cta%20next%20out%20of%20stock%20product)
   *
   * Quand un utilisateur a plusieurs produits indisponibles et qu'il rentre dans le tunnel de complétion, l'event est envoyé lors du clic sur le bouton "Produit indisponible suivant".
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  copyOfClickCtaNextOutOfStockProduct(
    options?: EventOptions,
  ) {
    return this.track(new CopyOfClickCtaNextOutOfStockProduct(), options);
  }

  /**
   * coupon activated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/coupon%20activated)
   *
   * Lorsqu'un user active un coupon au checkout
   *
   *
   * @param properties The event's properties (e.g. coupon name)
   * @param options Amplitude event options.
   */
  couponActivated(
    properties?: CouponActivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CouponActivated(properties), options);
  }

  /**
   * coupon deactivated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/coupon%20deactivated)
   *
   * Lorsqu'un user désactive un coupon au checkout
   *
   *
   * @param properties The event's properties (e.g. coupon name)
   * @param options Amplitude event options.
   */
  couponDeactivated(
    properties?: CouponDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CouponDeactivated(properties), options);
  }

  /**
   * coupon disabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/coupon%20disabled)
   *
   * Lorsqu’un user voit un coupon indisponible pour l’une des différentes causes rendant un coupon indisponible au checkout.
   *
   * Exemple avec le blocage d'un coupon parrain pour cause d'adresse similaire au parrain.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. dialog message)
   * @param options Amplitude event options.
   */
  couponDisabled(
    properties: CouponDisabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new CouponDisabled(properties), options);
  }

  /**
   * coupon modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/coupon%20modal%20viewed)
   *
   * When the user displays the details of a coupon
   *
   * @param options Amplitude event options.
   */
  couponModalViewed(
    options?: EventOptions,
  ) {
    return this.track(new CouponModalViewed(), options);
  }

  /**
   * create account started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/create%20account%20started)
   *
   * Quand la modale de création de compte s'affiche 
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  createAccountStarted(
    properties: CreateAccountStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountStarted(properties), options);
  }

  /**
   * create account submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/create%20account%20submitted)
   *
   * Quand l'utilisateur valide la création de son compte
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  createAccountSubmitted(
    properties: CreateAccountSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountSubmitted(properties), options);
  }

  /**
   * credit card added
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/credit%20card%20added)
   *
   * Quand la carte de crédit de l'utilisateur a bien été ajoutée
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  creditCardAdded(
    options?: EventOptions,
  ) {
    return this.track(new CreditCardAdded(), options);
  }

  /**
   * delete products confirmation modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/delete%20products%20confirmation%20modal%20viewed)
   *
   * Quand la modale de confirmation de suppression s’affiche.
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  deleteProductsConfirmationModalViewed(
    options?: EventOptions,
  ) {
    return this.track(new DeleteProductsConfirmationModalViewed(), options);
  }

  /**
   * delivery tracking modal closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/delivery%20tracking%20modal%20closed)
   *
   * Quand l'utilisateur ferme la modale de suivi de commande en cours.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. estimated arrival)
   * @param options Amplitude event options.
   */
  deliveryTrackingModalClosed(
    properties: DeliveryTrackingModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeliveryTrackingModalClosed(properties), options);
  }

  /**
   * delivery tracking modal refreshed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/delivery%20tracking%20modal%20refreshed)
   *
   * Quand l'utilisateur clique sur le bouton de mise à jour depuis la modale de suivi de commande.
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  deliveryTrackingModalRefreshed(
    options?: EventOptions,
  ) {
    return this.track(new DeliveryTrackingModalRefreshed(), options);
  }

  /**
   * delivery tracking modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/delivery%20tracking%20modal%20viewed)
   *
   * Quand l'utilisateur affiche la modale de suivi de sa commande via le bandeau affiché sur la page d'accueil.
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  deliveryTrackingModalViewed(
    options?: EventOptions,
  ) {
    return this.track(new DeliveryTrackingModalViewed(), options);
  }

  /**
   * didomi action
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/didomi%20action)
   *
   * Quand l'utilisateur fait une action sur la modale didomi (acceptation / refus des cookies)
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  didomiAction(
    properties: DidomiActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DidomiAction(properties), options);
  }

  /**
   * edit ingredient quantity
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/edit%20ingredient%20quantity)
   *
   * Sur la liste des ingrédients d'une recette, quand l'utilisateur modifie la quantité d'un ingrédient.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. ingredient quantity)
   * @param options Amplitude event options.
   */
  editIngredientQuantity(
    properties: EditIngredientQuantityProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditIngredientQuantity(properties), options);
  }

  /**
   * edit order click cta validated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/edit%20order%20click%20cta%20validated)
   *
   * Quand le user click pour confirmer la modification de sa commande sur la modale de confirmation
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  editOrderClickCtaValidated(
    properties: EditOrderClickCtaValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditOrderClickCtaValidated(properties), options);
  }

  /**
   * error modal view
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/error%20modal%20view)
   *
   * When the user sees a modal with an error or advertisment
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. dialog message)
   * @param options Amplitude event options.
   */
  errorModalView(
    properties: ErrorModalViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorModalView(properties), options);
  }

  /**
   * error payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/error%20payment)
   *
   * When an error occurs during the payment process
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. apiecom code)
   * @param options Amplitude event options.
   */
  errorPayment(
    properties: ErrorPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorPayment(properties), options);
  }

  /**
   * field error viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/field%20error%20viewed)
   *
   * Quand l'utilisateur a une erreur sur un champ en tentant de valider sa commande (champ manquant, CGV non cochée...).
   *
   *
   *
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  fieldErrorViewed(
    properties: FieldErrorViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FieldErrorViewed(properties), options);
  }

  /**
   * frequent purchases page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/frequent%20purchases%20page%20viewed)
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. in stock products)
   * @param options Amplitude event options.
   */
  frequentPurchasesPageViewed(
    properties?: FrequentPurchasesPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FrequentPurchasesPageViewed(properties), options);
  }

  /**
   * header marketing click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/header%20marketing%20click)
   *
   * Lorsque l'utilisateur clique sur un header marketing depuis la page d'accueil.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. content name)
   * @param options Amplitude event options.
   */
  headerMarketingClick(
    properties: HeaderMarketingClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new HeaderMarketingClick(properties), options);
  }

  /**
   * homepage viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/homepage%20viewed)
   *
   * Quand l'utilisateur affiche la page d'accueil.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  homepageViewed(
    properties: HomepageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageViewed(properties), options);
  }

  /**
   * Impossible add products modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Impossible%20add%20products%20modal%20viewed)
   *
   * Quand la modale “Il n’est plus possible d’ajouter des produits” s'affiche
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  impossibleAddProductsModalViewed(
    properties: ImpossibleAddProductsModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImpossibleAddProductsModalViewed(properties), options);
  }

  /**
   * ingredients substitutes modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/ingredients%20substitutes%20modal%20viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. initial product name)
   * @param options Amplitude event options.
   */
  ingredientsSubstitutesModalViewed(
    properties?: IngredientsSubstitutesModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IngredientsSubstitutesModalViewed(properties), options);
  }

  /**
   * landing page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/landing%20page%20viewed)
   *
   * ECOM Only : Quand l'utilisateur voit une Landing Page (valable uniquement pour les landing codées en dur, va devenir obsolète quand 100% des landings seront déployées via builder)
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  landingPageViewed(
    properties: LandingPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingPageViewed(properties), options);
  }

  /**
   * last order viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/last%20order%20viewed)
   *
   * Quand l'utilisateur accède à la liste des produits d'une de ses anciennes commandes/commandes en cours.
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. in stock products)
   * @param options Amplitude event options.
   */
  lastOrderViewed(
    properties?: LastOrderViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LastOrderViewed(properties), options);
  }

  /**
   * last orders page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/last%20orders%20page%20viewed)
   *
   * Lorsque l'utilisateur accède à la liste des ses denrières commandes (accessible depuis la homepage).
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  lastOrdersPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new LastOrdersPageViewed(), options);
  }

  /**
   * lastminute cross sell page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/lastminute%20cross%20sell%20page%20viewed)
   *
   * Quand le user voit la modale "envie d'autre chose ?"
   *
   *
   * ![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/124625/ef09561f-9cd6-417b-9afc-d71edc64d155?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG76EFRNKAJ%2F20250114%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20250114T101716Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEBcaCXVzLXdlc3QtMiJHMEUCIES3pGQQk6YMaX12HH64CJM7xCjrGrQCXHPK2J5ZB%2F%2FxAiEA94rmwG%2FS2bt65a3hV7BoFMNCBJ0zg2fM7Pd1wN2ERuUqtQUIEBAAGgwzNTgyMDMxMTU5NjciDMNtMrgXOVp7Ne0MyiqSBa3BDeVq8m4lYMUPC3oz9a9DcKrbe9Ccw%2FELVLTbIMKI%2FyaZwdy72oJm1ZtDWGHIY%2FNfJc3Nmje2ox1H1s4jZnb0eoa3OXbzgB8jcFXVne9q1ETO4pCo8WOP5hj%2FB3DujxAbyBdOVd2sOUn%2B42iv1COgR%2FG31LTss%2FqY6Bu%2FZptq8PVLd9UCUGl4%2Fqo5bImYw6%2Fu0StrcSdMTFX5xV%2BYIJ%2BLsMEIXktowNv1U%2BJkjSpjuZHty6iJ9Slav6tOzLD7astC5U4NvC9Iwjds7dAgGmef4960LoxYR%2BMs1nAdLtfgMbG3VpySE%2Bu6dztiSgbDiKLtBupL%2BqcGt7%2Bic0A3krAhKbn4WPod%2BFxh3nAXwEqbw6jtxhuY7LQeHtaSvc%2BF9aApatLTfQ7eXGlMLbKPfRdbaMFwmQ9%2F0PUGUTlhYnqgnSwCXOv59s8OMWCUJlTr10FQi7kOU4Uh3Kjry7Y%2B43vcXmyE89sZYjNQhURYgADMEiv0TNxP0PJynUwrkllJcZA340G%2F5PtBGoVruEHrkhXruUVgb1RKvST7aA4i%2BjY9iyMjxN1k%2B8XphtZ6vS2HWTxy7ChlkDEXWP%2FoQUHfGlKOjBdRpTd47MVGHWjuZASZtu0QF7UsZ65RirXyC2ZnQCYqu9tutiTjsoTv1v2NcAX4OGyj%2F2sZ12xIDcXoqmuUCRu1OBlpweazJe9wZ2OELk0sBN%2FifYAfeegL5htmiqO0H9UGZDRt%2FHpzclrpTBq5w7YnJPKgmVvVck92HKmXwFJayeJWBUj5qhFUIGAslZz0ZpTF2uHHcw65x1xl2IWHvd4T%2BqDlvcMJFqZqakWvnpJaad3HHd%2BQCUQWz03udKhlw3In%2FsJ3wDv6P%2FX5v8h%2BUsAw%2FZWYvAY6sQHW052wMINDlBm1QMTmvUY0kPWpI0NZzafNpZGYADaX4XXpjT7T8tKQJDlFHyhwv8V4NfoS4iIFJToWwoVEFP62HT2Wok1WInYXOozDQyl%2FCjzO3a%2BMEH%2Fsrk7dl6lKqyDAMMh67hstw8%2FHNxNwjTCa%2FXv96HokVMzMUKBaYy9rApO29qdfm5kFa2QKBkhumkXqEieHj7gcnOrdljLEmDAEQ7%2F91anMMMko7igf%2FLZi06E%3D&X-Amz-Signature=92562baac9416734689424b5f96a1a04bd5c8885fe31ca2d8ed7d62afb11f31a&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  lastminuteCrossSellPageViewed(
    properties: LastminuteCrossSellPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LastminuteCrossSellPageViewed(properties), options);
  }

  /**
   * logged in
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/logged%20in)
   *
   * when the user logs into their account. This event is not sent when the user creates his account.
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. log in account type)
   * @param options Amplitude event options.
   */
  loggedIn(
    properties?: LoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * logged in started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/logged%20in%20started)
   *
   * Quand la modale de login s'affiche 
   *
   *
   * \`
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  loggedInStarted(
    properties: LoggedInStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedInStarted(properties), options);
  }

  /**
   * logged in submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/logged%20in%20submitted)
   *
   * Quand l'utilisateur valide le formulaire de login
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  loggedInSubmitted(
    properties: LoggedInSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedInSubmitted(properties), options);
  }

  /**
   * logged out
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/logged%20out)
   *
   * when the user logs out
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  loggedOut(
    options?: EventOptions,
  ) {
    return this.track(new LoggedOut(), options);
  }

  /**
   * make new order modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/make%20new%20order%20modal%20viewed)
   *
   * Complément de commande - Quand la modale "Voulez-vous passer une nouvelle commade ?" s'affiche.
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  makeNewOrderModalViewed(
    properties: MakeNewOrderModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MakeNewOrderModalViewed(properties), options);
  }

  /**
   * marketing modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/marketing%20modal%20viewed)
   *
   * Quand l'utilisateur affiche le contenu d'une modale marketing (via une card ou un header marketing par exemple).
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. content name)
   * @param options Amplitude event options.
   */
  marketingModalViewed(
    properties: MarketingModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketingModalViewed(properties), options);
  }

  /**
   * marketing page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/marketing%20page%20viewed)
   *
   * ECOM uniquement : Quand l'utiliateur affiche une page marketing en pleine page, via l'URL direct par exemple : https://www.mon-marche.fr/info/livraison-grand-frais 
   *
   *
   * @param properties The event's properties (e.g. content name)
   * @param options Amplitude event options.
   */
  marketingPageViewed(
    properties: MarketingPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketingPageViewed(properties), options);
  }

  /**
   * my products category page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/my%20products%20category%20page%20viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category name)
   * @param options Amplitude event options.
   */
  myProductsCategoryPageViewed(
    properties?: MyProductsCategoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyProductsCategoryPageViewed(properties), options);
  }

  /**
   * my products informations modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/my%20products%20informations%20modal%20viewed)
   *
   * When the user displays the modal by clicking on the "my products" icon on a product card
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  myProductsInformationsModalViewed(
    properties: MyProductsInformationsModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MyProductsInformationsModalViewed(properties), options);
  }

  /**
   * next last minute cross sell
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/next%20last%20minute%20cross%20sell)
   *
   * Quand le user clic sur le CTA pour passer la modale de cross sell Last minute
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  nextLastMinuteCrossSell(
    properties: NextLastMinuteCrossSellProperties,
    options?: EventOptions,
  ) {
    return this.track(new NextLastMinuteCrossSell(properties), options);
  }

  /**
   * Open burger menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Open%20burger%20menu)
   *
   * Quand un utilisateur clique sur le burger menu (uniquement sur webmobile)
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  openBurgerMenu(
    options?: EventOptions,
  ) {
    return this.track(new OpenBurgerMenu(), options);
  }

  /**
   * opt-in toggle clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/opt-in%20toggle%20clicked)
   *
   * Quand l'utilisateur clique sur le toggle d'opt-in affiché sur le checkout (lors de la 2ème commande si l'utilisateur était opt-out).
   *
   *
   *
   *
   * @param properties The event's properties (e.g. opt-in status)
   * @param options Amplitude event options.
   */
  optInToggleClicked(
    properties: OptInToggleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OptInToggleClicked(properties), options);
  }

  /**
   * order cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/order%20cancelled)
   *
   * Lorsqu'une commande est annulée
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  orderCancelled(
    properties: OrderCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderCancelled(properties), options);
  }

  /**
   * order completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/order%20completed)
   *
   * When the user's order is successfully processed and prepayment is made.
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  orderCompleted(
    properties: OrderCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderCompleted(properties), options);
  }

  /**
   * order delivery information received
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/order%20delivery%20information%20received)
   *
   * Cet event est envoyé lorsque l'utilisateur affiche la modale de suivi de livraison d'une commande en cours.
   *
   * Il permet de récupérer les informations de la livraison.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. estimated arrival)
   * @param options Amplitude event options.
   */
  orderDeliveryInformationReceived(
    properties: OrderDeliveryInformationReceivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderDeliveryInformationReceived(properties), options);
  }

  /**
   * order reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/order%20reviewed)
   *
   * when the user clicks on an order in the "Mes commandes" tab and reviews their order
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  orderReviewed(
    properties?: OrderReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderReviewed(properties), options);
  }

  /**
   * order submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/order%20submitted)
   *
   * Quand l'utilisateur clique sur "payer ma commande"
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  orderSubmitted(
    properties: OrderSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderSubmitted(properties), options);
  }

  /**
   * packing reduction toggle click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/packing%20reduction%20toggle%20click)
   *
   * Sur le checkout, quand l'utilisateur clique sur le switch permettant de modifier sa préférence "Remplacement de produit".
   *
   *
   *
   *
   * Owner: Maxime Lange
   *
   * @param properties The event's properties (e.g. packing reduction status)
   * @param options Amplitude event options.
   */
  packingReductionToggleClick(
    properties: PackingReductionToggleClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new PackingReductionToggleClick(properties), options);
  }

  /**
   * payment info completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/payment%20info%20completed)
   *
   * When the payment info is validated
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. cart id)
   * @param options Amplitude event options.
   */
  paymentInfoCompleted(
    properties: PaymentInfoCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentInfoCompleted(properties), options);
  }

  /**
   * photo modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/photo%20modal%20viewed)
   *
   * Quand l'utiliateur affiche la modale permettant de visualiser les photos produits en plein écran.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. product category)
   * @param options Amplitude event options.
   */
  photoModalViewed(
    properties?: PhotoModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PhotoModalViewed(properties), options);
  }

  /**
   * product deleted from my products
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/product%20deleted%20from%20my%20products)
   *
   * Quand un produit est supprimé des produits de l’utilisateur.
   *
   * @param options Amplitude event options.
   */
  productDeletedFromMyProducts(
    options?: EventOptions,
  ) {
    return this.track(new ProductDeletedFromMyProducts(), options);
  }

  /**
   * product page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/product%20page%20viewed)
   *
   * Quand l'utilisateur affiche la page détails d'un produit.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. attribution token)
   * @param options Amplitude event options.
   */
  productPageViewed(
    properties: ProductPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProductPageViewed(properties), options);
  }

  /**
   * read less
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/read%20less)
   *
   * ECOM uniquement : Quand l'utilisateur affiche moins de texte sur un contenu masqué par défaut en cliquant sur "Lire moins".
   *
   * Par exemple : en footer de la page d'accueil.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  readLess(
    properties?: ReadLessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReadLess(properties), options);
  }

  /**
   * read more
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/read%20more)
   *
   * ECOM uniquement : Quand l'utilisateur affiche + de texte sur un contenu masqué en cliquant sur "Lire la suite".
   *
   * Par exemple : tete en footer de la page d'accueil.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. current page path)
   * @param options Amplitude event options.
   */
  readMore(
    properties?: ReadMoreProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReadMore(properties), options);
  }

  /**
   * recipe page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/recipe%20page%20viewed)
   *
   * Quand l'utilisateur voit une page recette
   *
   *
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  recipePageViewed(
    properties: RecipePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecipePageViewed(properties), options);
  }

  /**
   * referral code copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/referral%20code%20copied)
   *
   * Quand l'utilisateur copie son code parrain depuis son espace "Mon Compte".
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. referral code)
   * @param options Amplitude event options.
   */
  referralCodeCopied(
    properties: ReferralCodeCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReferralCodeCopied(properties), options);
  }

  /**
   * referral code shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/referral%20code%20shared)
   *
   * Quand l'utilisateur partage son code parrain en cliquant sur le bouton "Je partage mon code" depuis "Mon compte".
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. referral code)
   * @param options Amplitude event options.
   */
  referralCodeShared(
    properties: ReferralCodeSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReferralCodeShared(properties), options);
  }

  /**
   * referral modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/referral%20modal%20viewed)
   *
   * ECOM uniquement : Lorsque l'utilisateur passe sa 3ème commande, la modale de confirmation est différente et affiche les informations de parrainage.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. user referral code)
   * @param options Amplitude event options.
   */
  referralModalViewed(
    properties: ReferralModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReferralModalViewed(properties), options);
  }

  /**
   * roll out photo carousel
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/roll%20out%20photo%20carousel)
   *
   * Quand l'utilisateur change de visuel produit sur le carrousel des photos d'une fiche produit.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. opened from)
   * @param options Amplitude event options.
   */
  rollOutPhotoCarousel(
    properties: RollOutPhotoCarouselProperties,
    options?: EventOptions,
  ) {
    return this.track(new RollOutPhotoCarousel(properties), options);
  }

  /**
   * root page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/root%20page%20viewed)
   *
   * Quand l'utilisateur arrive sur une page "racine" générée depusi Builder.io , par exemple : https://www.mon-marche.fr/plan-du-site
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  rootPageViewed(
    properties: RootPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RootPageViewed(properties), options);
  }

  /**
   * scroll horizontal list
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/scroll%20horizontal%20list)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. subcategory name)
   * @param options Amplitude event options.
   */
  scrollHorizontalList(
    properties?: ScrollHorizontalListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScrollHorizontalList(properties), options);
  }

  /**
   * search completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/search%20completed)
   *
   * Quand l'utilisateur valide une recherche (soit en saisissant du texte libre, soit en saisissant un terme proposé, soit en selectionnant une catégorie, soit en sélectionnant un mot clé populaire -> Voir la propriété "search type")
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  searchCompleted(
    properties: SearchCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * search started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/search%20started)
   *
   * Quand l'utilisateur accède au champ de recherche :
   *
   * * Sur ECOM, au clic dans le champ
   *
   * * Sur MCOM, au clic sur la tab (focus auto sur le champ)
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  searchStarted(
    properties?: SearchStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchStarted(properties), options);
  }

  /**
   * send proposal
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/send%20proposal)
   *
   * Depuis l'écran "Aucun résulat de recherche", lorsque l'utilisateur envoie une suggestion de produi.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. proposal text)
   * @param options Amplitude event options.
   */
  sendProposal(
    properties: SendProposalProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendProposal(properties), options);
  }

  /**
   * Send sms code
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Send%20sms%20code)
   *
   * Quand l'utilisateur clique sur "je crée mon compte" et demande implicitement l'envoie du SMS de validation
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. telephone number)
   * @param options Amplitude event options.
   */
  sendSmsCode(
    properties?: SendSmsCodeProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendSmsCode(properties), options);
  }

  /**
   * shipping cta click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20cta%20click)
   *
   * Lorsque le user clic sur les onglets de choix "Livraison" ou "Retrait"
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  shippingCtaClick(
    properties: ShippingCtaClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingCtaClick(properties), options);
  }

  /**
   * shipping information modal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20information%20modal%20viewed)
   *
   * Quand l'utilisateur affiche une modale d'information depuis la page de sélection des créneaux, il peut s'agir d'une modale :
   *
   * * Livraison offerte
   *
   * * Quand commander ?
   *
   * * Information sur une zone d'exclusion
   *
   *
   *
   *
   * @param properties The event's properties (e.g. excludedZone id)
   * @param options Amplitude event options.
   */
  shippingInformationModalViewed(
    properties: ShippingInformationModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingInformationModalViewed(properties), options);
  }

  /**
   * shipping slot closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20closed)
   *
   * Lorsque l'utilisateur quitte l'écran des créneaux sans avoir choisi de nouveau créneau (n'est pas déclenché par l'écran de la fonction "Changer de créneau").
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param options Amplitude event options.
   */
  shippingSlotClosed(
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotClosed(), options);
  }

  /**
   * shipping slot day viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20day%20viewed)
   *
   * Lorsque le user clic sur un jour de livraison dans la sélection de créneau et voit les créneaux d'un nouveau jour de livraison
   *
   *
   * Owner: gregoire.coulon@mon-marche.fr
   *
   * @param properties The event's properties (e.g. available slots)
   * @param options Amplitude event options.
   */
  shippingSlotDayViewed(
    properties?: ShippingSlotDayViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotDayViewed(properties), options);
  }

  /**
   * shipping slot start update
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20start%20update)
   *
   * Lorsque le user clic sur "Changer" pour modifier le créneau d'une commande en cours dans "Mon compte"
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  shippingSlotStartUpdate(
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotStartUpdate(), options);
  }

  /**
   * shipping slot started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20started)
   *
   * Lorsque l'utilisateur commence à interagir avec le formulaire de choix de créneau
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. pickup mode)
   * @param options Amplitude event options.
   */
  shippingSlotStarted(
    properties: ShippingSlotStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotStarted(properties), options);
  }

  /**
   * shipping slot toaster click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20toaster%20click)
   *
   * Lorsque le user clic sur "Modifier" sur le toaster de confirmation de créneau
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  shippingSlotToasterClick(
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotToasterClick(), options);
  }

  /**
   * shipping slot toaster viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20toaster%20viewed)
   *
   * Lorsque le user voit le toaster de confirmation de créneau s'afficher
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  shippingSlotToasterViewed(
    properties?: ShippingSlotToasterViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotToasterViewed(properties), options);
  }

  /**
   * shipping slot updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20updated)
   *
   * Lorsque le créneau est modifié pour une commande existante
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. available slots)
   * @param options Amplitude event options.
   */
  shippingSlotUpdated(
    properties: ShippingSlotUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotUpdated(properties), options);
  }

  /**
   * shipping slot validated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20validated)
   *
   * Lorsque le créneau est affecté au user
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. available slots)
   * @param options Amplitude event options.
   */
  shippingSlotValidated(
    properties: ShippingSlotValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotValidated(properties), options);
  }

  /**
   * shipping slot viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20slot%20viewed)
   *
   * Lorsque le user voit l'écran de choix du créneau
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  shippingSlotViewed(
    options?: EventOptions,
  ) {
    return this.track(new ShippingSlotViewed(), options);
  }

  /**
   * shipping type refused
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20type%20refused)
   *
   * Lorsque l'adresse saisie est en dehors de la zone de livraison
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  shippingTypeRefused(
    properties: ShippingTypeRefusedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingTypeRefused(properties), options);
  }

  /**
   * shipping type started
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20type%20started)
   *
   * Lorsque le user commence à taper son adresse
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  shippingTypeStarted(
    properties: ShippingTypeStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingTypeStarted(properties), options);
  }

  /**
   * shipping type submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20type%20submitted)
   *
   * Lorsque le user soumet son choix d'adresse/PDR
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  shippingTypeSubmitted(
    properties: ShippingTypeSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingTypeSubmitted(properties), options);
  }

  /**
   * shipping type validated
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20type%20validated)
   *
   * Lorsque l'adresse ou le PDR est validé et ajouté comme user property au user
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. delivery zone/pick-up point)
   * @param options Amplitude event options.
   */
  shippingTypeValidated(
    properties: ShippingTypeValidatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingTypeValidated(properties), options);
  }

  /**
   * shipping type viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/shipping%20type%20viewed)
   *
   * Lorsque le user voit l'écran de sélection d'adresse
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. event source)
   * @param options Amplitude event options.
   */
  shippingTypeViewed(
    properties: ShippingTypeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShippingTypeViewed(properties), options);
  }

  /**
   * similar products list viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/similar%20products%20list%20viewed)
   *
   * **When the user sees a similar products list**
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. number of similar products viewed)
   * @param options Amplitude event options.
   */
  similarProductsListViewed(
    properties?: SimilarProductsListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimilarProductsListViewed(properties), options);
  }

  /**
   * Sms code accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Sms%20code%20accepted)
   *
   * When the user register the right code his received by sms
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. telephone number)
   * @param options Amplitude event options.
   */
  smsCodeAccepted(
    properties?: SmsCodeAcceptedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsCodeAccepted(properties), options);
  }

  /**
   * Sms code refused
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/Sms%20code%20refused)
   *
   * Quand l'utilisateur renseigne un mauvais code de vérification lors de son inscription.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. telephone number)
   * @param options Amplitude event options.
   */
  smsCodeRefused(
    properties?: SmsCodeRefusedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsCodeRefused(properties), options);
  }

  /**
   * start proposal
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/start%20proposal)
   *
   * Quand il y a peu ou pas de résultat dans la Recherche, il est proposé à l'utilisateur de suggérer des produits.
   *
   * Cet event est envoyé quand l'utilisateur clique sur le bouton "Dites le nous".
   *
   *
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  startProposal(
    options?: EventOptions,
  ) {
    return this.track(new StartProposal(), options);
  }

  /**
   * subcategory click title
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/subcategory%20click%20title)
   *
   * Uniquement ECOM.
   *
   * Quand l'utilisateur clique sur le titre d'une sous-catégorie depuis une page catégorie.
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category name)
   * @param options Amplitude event options.
   */
  subcategoryClickTitle(
    properties?: SubcategoryClickTitleProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubcategoryClickTitle(properties), options);
  }

  /**
   * subcategory page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/subcategory%20page%20viewed)
   *
   * Uniquement ECOM.
   *
   * Quand l'utilisateur accède à la page d'une sous-catégorie :
   *
   * * soit via un lien direct, par ex : https://www.mon-marche.fr/categorie/poissons-entiers
   *
   * * soit en cliquant sur le titre d'une sous-catégorie depuis une page catégorie
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. available products)
   * @param options Amplitude event options.
   */
  subcategoryPageViewed(
    properties: SubcategoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubcategoryPageViewed(properties), options);
  }

  /**
   * subcategory viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/subcategory%20viewed)
   *
   * Quand une sous-catégorie est vue, c'est à dire que l'utilisateur a vu les produits d'une sous-catégorie en scrollant sur la page.
   *
   * @param properties The event's properties (e.g. category name)
   * @param options Amplitude event options.
   */
  subcategoryViewed(
    properties: SubcategoryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubcategoryViewed(properties), options);
  }

  /**
   * unavailable products replacement information click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/unavailable%20products%20replacement%20information%20click)
   *
   * Quand l'utilisateur clique sur le "i" à côté du switch demande d'autoriser le remplacement automatique des produits manquants de la commande.
   *
   * Ce "i" permet d'afficher une modale d'information sur le fonctionnement du remplacement auto.
   *
   *
   *
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  unavailableProductsReplacementInformationClick(
    options?: EventOptions,
  ) {
    return this.track(new UnavailableProductsReplacementInformationClick(), options);
  }

  /**
   * unavailable products replacement toggle click
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/unavailable%20products%20replacement%20toggle%20click)
   *
   * Quand l'utilisateur clique sur le switch visible depuis le panier pour activer ou non le remplacement.
   *
   * La valeur de la property "unavailable products replacement status" permet de savoir si l'utilisateur a activé ou désactivé le switch.
   *
   *
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. unavailable products replacement status)
   * @param options Amplitude event options.
   */
  unavailableProductsReplacementToggleClick(
    properties: UnavailableProductsReplacementToggleClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnavailableProductsReplacementToggleClick(properties), options);
  }

  /**
   * user navigation basket
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20basket)
   *
   * Quand l'utilisateur clique sur l'icône du panier.
   *
   *
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  userNavigationBasket(
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationBasket(), options);
  }

  /**
   * user navigation card category
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20card%20category)
   *
   * Uniquement MCOM.
   *
   * Quand l'utiliateur tap sur une catégorie depuis la homepage.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. category position)
   * @param options Amplitude event options.
   */
  userNavigationCardCategory(
    properties: UserNavigationCardCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationCardCategory(properties), options);
  }

  /**
   * user navigation footer
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20footer)
   *
   * Quand l'utilisateur clique sur l'un des liens du footer.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. navigation text)
   * @param options Amplitude event options.
   */
  userNavigationFooter(
    properties?: UserNavigationFooterProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationFooter(properties), options);
  }

  /**
   * user navigation header
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20header)
   *
   * Uniquement ECOM.
   *
   * Quand l'utilisateur clique sur l'icône Mon compte.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. navigation text)
   * @param options Amplitude event options.
   */
  userNavigationHeader(
    properties?: UserNavigationHeaderProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationHeader(properties), options);
  }

  /**
   * user navigation menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu)
   *
   * Quand l'utilisateur clique sur bouton du menu :
   *
   * * ECOM : Logo, la recherche, le champ adresse...
   *
   * * MCOM : La tabbar, monc ompte, le champ adresse...
   *
   *
   *
   * * Utiliser la propriété "navigation text" pour filtrer sur le nom de l'entrée
   *
   *
   *
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. navigation text)
   * @param options Amplitude event options.
   */
  userNavigationMenu(
    properties?: UserNavigationMenuProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenu(properties), options);
  }

  /**
   * user navigation menu category
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu%20category)
   *
   * Uniquement ECOM.
   *
   * Quand l'utilisateur clique sur une catégorie depuis la liste des catégories.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. category Id)
   * @param options Amplitude event options.
   */
  userNavigationMenuCategory(
    properties?: UserNavigationMenuCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenuCategory(properties), options);
  }

  /**
   * user navigation menu family
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu%20family)
   *
   * Quand un utilisateur clic sur une famille dans le mégamenu (ECOM desktop Only)
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  userNavigationMenuFamily(
    properties: UserNavigationMenuFamilyProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenuFamily(properties), options);
  }

  /**
   * user navigation menu my products category
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu%20my%20products%20category)
   *
   * Quand l’utilisateur clique sur un raccourci de catégories dans sa liste “Mes produits”.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category Id)
   * @param options Amplitude event options.
   */
  userNavigationMenuMyProductsCategory(
    properties?: UserNavigationMenuMyProductsCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenuMyProductsCategory(properties), options);
  }

  /**
   * user navigation menu portal
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu%20portal)
   *
   * Quand l'utilisateur clique sur une entrée du menu "Mon compte".
   *
   *
   * Utiliser la pripriété "navigation text" pour connaitre le nom de l'entrée
   *
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. navigation text)
   * @param options Amplitude event options.
   */
  userNavigationMenuPortal(
    properties?: UserNavigationMenuPortalProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenuPortal(properties), options);
  }

  /**
   * user navigation menu subcategory
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20navigation%20menu%20subcategory)
   *
   * Uniquement ECOM
   *
   * Quand l'utilisateur clique sur une sous-catégorie dans le menu de gauche sur la page d'une catégorie.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. navigation text)
   * @param options Amplitude event options.
   */
  userNavigationMenuSubcategory(
    properties?: UserNavigationMenuSubcategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserNavigationMenuSubcategory(properties), options);
  }

  /**
   * user portal click continue shopping
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20portal%20click%20continue%20shopping)
   *
   * Uniquement sur ECOM, quand l'utilisateur clique sur le bouton "Continuer mes achats" depuis l'écran "Mon compte". 
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  userPortalClickContinueShopping(
    options?: EventOptions,
  ) {
    return this.track(new UserPortalClickContinueShopping(), options);
  }

  /**
   * user portal click referral card
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20portal%20click%20referral%20card)
   *
   * Quand le user tap sur l'encart de parrainage dans son compte.
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param options Amplitude event options.
   */
  userPortalClickReferralCard(
    options?: EventOptions,
  ) {
    return this.track(new UserPortalClickReferralCard(), options);
  }

  /**
   * user portal viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/user%20portal%20viewed)
   *
   * when the user views a user portal page (/account/orders, /account/preferences ... )
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. current page name)
   * @param options Amplitude event options.
   */
  userPortalViewed(
    properties: UserPortalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserPortalViewed(properties), options);
  }

  /**
   * view all reviews
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/view%20all%20reviews)
   *
   * Lorsque le user clique sur le CTA "Voir tous les avis" sur la fiche produit.
   *
   *
   * @param properties The event's properties (e.g. nb of ratings)
   * @param options Amplitude event options.
   */
  viewAllReviews(
    properties: ViewAllReviewsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAllReviews(properties), options);
  }

  /**
   * view favorites list
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/view%20favorites%20list)
   *
   * Quand le user accède à sa liste de favoris.
   *
   * Il peut y accéder via l'icône ❤️ pour accèder à sa liste de produits favoris :
   *
   * * Dans le Header du ECOM
   *
   * * Dans la Tab bar sur MCOM
   *
   *
   *
   *
   * Owner: axelle@gokepler.fr
   *
   * @param properties The event's properties (e.g. in stock products)
   * @param options Amplitude event options.
   */
  viewFavoritesList(
    properties: ViewFavoritesListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewFavoritesList(properties), options);
  }

  /**
   * view search results
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/view%20search%20results)
   *
   * Lorsque les résultats de recherche sont affichés.
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. algorithm)
   * @param options Amplitude event options.
   */
  viewSearchResults(
    properties: ViewSearchResultsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSearchResults(properties), options);
  }

  /**
   * view suggestions results
   *
   * [View in Tracking Plan](https://data.amplitude.com/monmarche/Production/events/main/latest/view%20suggestions%20results)
   *
   * Quand l’utilisateur voit la liste des termes suggérés. Cela peut être :
   *
   * * Des mots-clés (issus actuellement de catégories PIM)
   *
   * * Des sous-catégories marketing
   *
   * ![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/124625/d493cf11-a6db-4847-85a8-9995d336ea3e.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG7QCK5QBID%2F20250114%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20250114T094607Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEBgaCXVzLXdlc3QtMiJHMEUCIQCgJfu8dxS1GlT5HMhilXiEFtx1b6VFm8IKMb3AV5uU7AIgNTSIRzEAJo3DuUV3hsI8D9YcaaR1NyUzy8KcSz27ivEqtAUIERAAGgwzNTgyMDMxMTU5NjciDHS8puR%2BZajSeSzCkSqRBcFmRQ6tVjO2l7Qh5NjBsqT6IlPwF6cVk1zfPt8dLFp71sU2wyPoRuV42ZBnmIepm5lXinAEQekZMgj%2BQ44S3FVkfmBKuBGjq3g5HgUkrH4TaMklmTdw265SXy4TYGAiN7gD9Cw4a3G3E9oFmET37%2FHB4%2BGzhVNwKV3%2B9JRrh5ftFuirdokMb%2Bp1d3d1Zi0VNzH0LeW3TiYMwfxWKzGm14WlgU%2FAsEoSRCkQigQfKXzivXz3BEAvwfBLP2OdqudyRXdm4ERoejcozf0zvid%2BkhwkrlyYK48ilB4uKmEmAddJuDsVuZE98fXBPV99LI9wL7QeSzfQPQitqlTCrKWF5pc0mO%2FZT1WbDhBR5rf%2Bt9%2BC6YO53n53nMKhk%2FEHO1wbJw7JbauHvXRVIud2zHHgB0xuaajhJSluuileybNHmUTm0cdl%2BLGL6Bb3YYktIa%2B5s%2Fdgznrt4GYsXxhipysMO9SUIj54qbIBJz0qH0kdHdSRPxcg2hx1fn3tvE2fxe74AhhsYJ484cbMSCTrNGOHrLPDmSCRiNACQwvZpyumS1%2Fo6mOYn859qfNbYzL6FYM7FYNF7mvTEoZ7Dz3bzpeHTd4qEa7Zs1NGBEcpSg8GXbjuoh6KSGz3Q8Qj2ei7KThCbvwQJPk4vrrxBFF%2F1r3Brh8X%2FpafeYUNS4OU%2B3MHsCm75R%2B07j6WobnPqmQRJtSPo1cUB0abfOhcjXPzWOc2htVLHklu2LsO3xErhi4cdzkMQ7W%2BGqxiUPpEII4ecsXCoCRGZ5366L2x%2BwqCyQPsopsBxMYV%2FvB5rXhiSTtBSRgznKPevQ5028Fghc2IY4Ue1AQbF4KhLX%2Fpi4pQnRtc9a6PLfBox5WQ8t2dRVpQ4kc%2FSjDErpi8BjqxAZWkJOWy24dPRdQ%2FjlYtrELM6ZeovnodrHgr1v5%2Fx6PQY%2FpSf6E1T2UMrnDGbHtg58wUe47dsrwxYpLNJLLqjQV2Y%2FMwbBwAclLISyvl2u31BYuSVZh3k9tsKM9%2FHICTDUBE20d9NNqH8G5lTDv1KtIA3iNjZku9e2jSvadvtllWY%2FP67%2BzJhb06LNY1WK6z4vTj8k7dejNxosvd0FUdSgih8JhS1maI5mTYRW6keJr5CA%3D%3D&X-Amz-Signature=0497cea94f0e541b16c47f88adf1ee68dabd26409e450e79acda197f88331b01&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * @param properties The event's properties (e.g. Number of results)
   * @param options Amplitude event options.
   */
  viewSuggestionsResults(
    properties: ViewSuggestionsResultsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSuggestionsResults(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
